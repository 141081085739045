import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Layout/Header";
import ClientLogoCarousel from "../../Components/Home/ClientLogoCarousel";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import TestimonialsandBlogSec from "../../Components/Home/TestimonialsandBlogSec";
import WhyUs from "../../Components/Home/WhyUs";
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import getHelmetContent from "../../Components/Common/HelmetData";
import Gallery from "../../Components/Home/Gallery";
import FormModal from "../../Components/Home/FormModal";

const Career = () => {
  const historyRef = useRef(null);
  useEffect(() => {
    document.title = "Career - Think Quotient";

    AOS.init();

    if (window.location.hash === "#history") {
      scrollToHistory();
    }
  }, []);

  const scrollToHistory = () => {
    if (historyRef.current) {
      historyRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // const images = [
  //   "/assets/images/team-1.png",
  //   "/assets/images/team-6.png",
  //   "/assets/images/team-3.png",
  //   "/assets/images/team-4.png",
  //   "/assets/images/team-5.png",
  //   "/assets/images/team-2.png",
  // ];

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const [showMore1, setShowMore1] = useState(false);
  const [showMore2, setShowMore2] = useState(false);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage("");
    setModalOpen(false);
  };

  const [modalData, setModalData] = useState(null);
  const [isModalOpenForResume, setModalOpenForResume] = useState(false);

  const openModalForResume = (data) => {
    setModalData(data);
    setModalOpenForResume(true);
  };

  const closeModalForResume = () => {
    setModalData(null);
    setModalOpenForResume(false);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();

  const handleShowMore = (value) => {
    value === 1 ? setShowMore1(!showMore1) : setShowMore2(!showMore2);
    // if(value == 1){
    //   setShowMore1(!showMore1)
    // }
    // if(value == 0) {

    // }
  };

  return (

    <>
      <Helmet>

        <meta name="title" content={metaTitle} />
        {/* Remaining meta tag */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* scipt for facebook */}
        {helmetContent.script}
        <script>
          {`
          (function (b, m, h, a, g) {
            b[a] = b[a] || [];
            b[a].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var k = m.getElementsByTagName(h)[0],
              e = m.createElement(h),
              c = a != "dataLayer" ? "&l=" + a : "";
            e.async = true;
            e.src = "https://www.googletagmanager.com/gtm.js?id=" + g + c;
            k.parentNode.insertBefore(e, k);
          })(window, document, "script", "dataLayer", "GTM-TN6B4BK");
          `
          }
        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>,

        <script>
          {
            `
      window.dataLayer = window.dataLayer || []; 
      function gtag() { 
        dataLayer.push(arguments) 
      } gtag("js", new Date()); gtag("config", "UA-143016865-1");
       `}
        </script>,

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>,

        <script>
          {`

        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments)}
        gtag('js', new Date());

        gtag('config', 'G-BSFPPN5E9D');
        `}

        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>,

        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments) }
      gtag('js', new Date());

      gtag('config', 'AW-574047969');
      `}

        </script>

      </Helmet>
      <div className="overflow-hidden">
        {/* Header Section */}
        <Header />

        {/* Banner Section */}
        <div>
          <div className="student-banner-sec flex items-center">
            <div className="container text-left text-white mx-auto">
              <div
                className="md:w-1/2 mx-4 md:mx-0"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h1 className="text-2xl md:text-6xl font-semibold mb-4 ">
                  Careers
                </h1>
                <p className="text-lg mb-8">
                  ThinkQuotient's exponential growth is attributed to the
                  determination, vision, and teamwork of its employees.
                </p>
                {/* <Link to="/careers/apply-to-work">
                <button className="btn-style-2 px-6 py-2 ">
                  Apply to work with US
                </button>
              </Link> */}
              </div>
            </div>
          </div>
        </div>

        {/* Humans of zensar section */}
        {/* <HumansOfThinkQuotient /> */}

        {/* Why to join thinkquotient? */}
        <div className="container md:flex mx-auto px-3">
          <h1
            className="text-2xl md:text-3xl font-semibold text-left mb-10 list-items px-4"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            Why to join ThinkQuotient ?
          </h1>
          <h1
            ref={historyRef}
            className="text-base font-normal container mx-auto text-left px-5"
            id="history"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            A group of individuals who exemplify success, unity, diversity,
            professionalism, and family-like bonding. Through our teamwork, we
            have achieved great things, and we continue to push the boundaries of
            what is possible in the world of software development.
          </h1>
        </div>

        <div className="flex flex-col items-center py-14  bg-gray-100 fade-in-down px-6">
          <h1 className="text-4xl font-bold container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000">
            Current Openings
          </h1>
        </div>

        {/* Business Development*/}
        <div class="grid grid-cols-1 md:grid-cols-2">
          <div className="border-solid border-gray-100 border-2">
            <div className="container mx-auto flex flex-col justify-center px-2 py-10"
              data-aos="fade-up"
              data-aos-duration="1000">
              <h1 className="text-2xl font-bold">Business Development:</h1>
              <p className="mt-4 md:w-[80%] list-items ps-6">
                Job Description- Business Development Officer
              </p>
              <p className="mt-4 md:w-[80%] list-items ps-6 ">Salary- 3-3.6 LPA depending on Interview performance</p>
              <p className="mt-4 md:w-[80%] list-items ps-6 ">
                Notice Period - 2 Months
              </p>
              <p className="mt-4 md:w-[80%] list-items ps-6 ">
                Probation Period- 3 Months
              </p>



              <p className="md:w-[80%]  mt-6 font-semibold text-lg">
                Job Description
              </p>
              <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
                Plan and execute marketing and business development activities.
              </p>
              <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
                Maintain records of calls, sales, and customer information.
              </p>
              
              {showMore1 == false && (
                <div className="text-right">
                  <button className="btn-style-3 px-6 py-2 mt-8 md:w-1/4 mr-5 sm:w-2/4" onClick={() => handleShowMore(1)}>
                    Read More
                  </button>
                </div>
              )}
              {showMore1 && (
                <>


                 
                  <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
                    Implement marketing strategies for organizational growth.
                  </p>
                  <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
                    Research market trends and identify business opportunities.
                  </p>
                  <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
                    Propose and implement improvements to existing strategies.
                  </p>
                  <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
                    Collaborate with company management and suggest process enhancements.
                  </p>


                  <p className="mt-4 md:w-[80%] mb-2 font-semibold text-lg">
                    Required Qualification and Skills
                  </p>
                  <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
                    Should have Basic Computer Knowledge.
                  </p>
                  <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
                    Should have Excellent Communication and Interpersonal Skills
                  </p>
                  <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
                    Should possess excellent Convincing Power.
                  </p>
                  <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
                    Fluent in English, Marathi, and Hindi
                  </p>



                  <div className="w-full flex justify-center">
                 
                    <button className="btn-style-3 px-6 py-2 mt-8 " onClick= {() => openModalForResume("Business Development")}>
                      Upload Resume →
                    </button>

                  </div>
                  <FormModal isOpen={isModalOpenForResume} onRequestClose={closeModalForResume} initialData={modalData}/>
                </>
              )}
            </div>

          </div>

          {/* Technical Trainer*/}
          <div className="border-solid border-gray-100 border-2">
            <div className="container mx-auto border-b "
              data-aos="fade-up"
              data-aos-duration="1000">
              <div className="flex flex-col justify-center px-2 py-10">
                <h1 className="text-2xl font-bold">Technical Trainer:</h1>

                <p className="mt-4 md:w-[90%] list-items ps-6 mb-2">
                  <b>Education- </b> <br /> UG: B.Tech/B.E.-Computers,BCA -Computers,B.Sc- Computers.   <br /> PG: M.Tech-Computers,MCA-Computers,MS/M.Sc(Science)-Computers    </p>


                <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
                  <b>Key Skills-  </b> <br />
                  Core Java, Advanced Java, Angular, Spring Boot, Java Trainer, BI/Data Analytics, Software Trainer, IT Training
                  <br />

                </p>
                <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
                  <b>Location-</b>  Pune<br />
                </p>
                <p className="md:w-[80%]  mt-6 font-semibold text-lg">
                      Job Description
                    </p>
                    <p className="mt-4 md:w-[90%] list-items ps-6 mb-2">
                      Conduct classroom sessions (lectures & practical) as per the curriculum.
                    </p>
                

                {showMore2 == false && (
                  <div className="text-right">
                    <button className="btn-style-3 px-6 py-2 mt-3 md:w-1/4 mr-5 sm:w-2/4 mr-5" onClick={() => handleShowMore(0)}>
                      Read More
                    </button>
                  </div>
                )}

                {showMore2 && (
                  <>
                    


                    {/* <p className="md:w-[80%]  my-6 font-semibold text-lg">Job Summary</p>
              <p className="w-11/12">
                The Placement Team Resource plays a crucial role in supporting the
                placement team's efforts to connect candidates with suitable
                employment opportunities. This position involves a combination of
                administrative tasks, relationship management, and communication
                skills to ensure a seamless placement process.
              </p> */}
                    
                    <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
                      Analyze & generatere ports for each student's progress.
                    </p>
                   
                    <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
                      Prepare technical content and assess test papers.
                    </p>
                    <p className="mt-4 md:w-[100%] list-items ps-6 mb-2">
                      Resolve students' queries and ensure concepts are understood/implemented correct.
                    </p>



                    <p className="mt-4 md:w-[80%] mb-2 font-semibold text-lg">
                      Required Candidate profile
                    </p>
                    <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
                      Expert in Core Java.         </p>
                    <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
                      Should possess excellent communication skills.          </p>
                    <p className="mt-4 md:w-[90%] list-items ps-6 mb-2">
                      Good to have knowledge about Spring framework, Angular, React JS.          </p>
                    <p className="mt-4 md:w-[90%] list-items ps-6 mb-2">
                      Should be ready to learn new technologies and willing to upgrade skills.          </p>
                    <div className="w-full flex justify-center">
                      <button className="btn-style-3 px-6 py-2 mt-12 " onClick= {() => openModalForResume("Technical Trainer")}>
                        Upload Resume →
                      </button>
                    </div>
                  </>
                )}
              </div>
             </div>
            </div>
          </div>

          {/* Card Flip */}

          <div className="container-card py-10 bg-gray-100 ">
            <h1 className="text-2xl md:text-3xl font-semibold text-center mb-10">
              Our History
            </h1>
            <div
              className="services-card md:col-span-6"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="service-card service1-card">
                <div className="front-card">
                  <h4>2023</h4>
                </div>
                <div className="back-card">
                  <p>Expansion - ThinkQuotient: Entry into Franchise Model</p>
                </div>
              </div>
              <div className="service-card service3-card">
                <div className="front-card">
                  <h4>2022</h4>
                </div>
                <div className="back-card">
                  <p>
                    Stability - Steady Progress, 1700+ Placement & 400+ Company
                    Tie-Ups ThinkQuotient launched ZCM Program with Pay After
                    Placement Model.
                  </p>
                </div>
              </div>
              <div className="service-card service2-card">
                <div className="front-card">
                  <h4>2021</h4>
                </div>
                <div className="back-card">
                  <p>
                    Recovery Phase - 700+ placements done. Bulk resource recruitment
                    along with customised training for corporate companies.
                  </p>
                </div>
              </div>
            </div>
            <div className="services-card"
              data-aos="fade-up"
              data-aos-duration="1000">
              <div className="service-card service2-card">
                <div className="front-card">
                  <h4>2020</h4>
                </div>
                <div className="back-card">
                  <p>
                    Transition to Online - online_training-TAP Entering in Top IT
                    MNC's New Branches Across India & World
                  </p>
                </div>
              </div>
              <div className="service-card service1-card">
                <div className="front-card">
                  <h4>2019</h4>
                </div>
                <div className="back-card">
                  <p>
                    Scaled to next level, Training & Placement Program 300+
                    Placements & 100+ IT Companies Launched ThinkQuotient
                    Developers' Program, Examo-TAP(Talent Assessment Portal) and
                    online_training-TAP entering in Top IT MNCs.
                  </p>
                </div>
              </div>
              <div className="service-card service3-card">
                <div className="front-card">
                  <h4>2018</h4>
                </div>
                <div className="back-card">
                  <p>Conducted over 200+ drives and place over 100+ students.</p>
                </div>
              </div>
            </div>
            <div className="services-card"
              data-aos="fade-up"
              data-aos-duration="1000">
              <div className="service-card service3-card">
                <div className="front-card">
                  <h4>2017</h4>
                </div>
                <div className="back-card">
                  <p>
                    Launched ThinkQuotient Placement Program. Bridging the Gap
                    between Students & IT Companies.
                  </p>
                </div>
              </div>
              <div className="service-card service2-card">
                <div className="front-card">
                  <h4>2016</h4>
                </div>
                <div className="back-card">
                  <p>
                    Developed a state of art Edtech product "online_training". Built
                    a customer base of top 70 institutes for online_training.
                  </p>
                </div>
              </div>

              <div className="service-card service1-card">
                <div className="front-card">
                  <h4>2015</h4>
                </div>
                <div className="back-card">
                  <p>
                    Company Formed, Building Relevant (Learning Management Systems).
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Life at thinkquotient */}
          {/* <div className="py-10 px-4 md:px-0">
        <div className="container mx-auto">
          <h1 className="capitalize text-center sm:col-span-2 lg:col-span-4 text-3xl sm:text-5xl xl:text-3xl font-bold pb-10">
            Life At ThinkQuotient
          </h1>
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index} className="w-full py-6">
                <img
                  src={image.replace("1400/768", "600")}
                  className="rounded object-cover pe-2"
                  alt={`gallery-image-${index}`}
                  onClick={() => openModal(image)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div> */}

          <Gallery />

          <TestimonialsandBlogSec />

         
          <ClientLogoCarousel />
          <Footer />
        </div>
      </>
      );
};

      export default Career;
