import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Header from "../../Components/Layout/Header";
import ClientLogoCarousel from "../../Components/Home/ClientLogoCarousel";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import TestimonialsandBlogSec from "../../Components/Home/TestimonialsandBlogSec";
import WhyUs from "../../Components/Home/WhyUs";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import getHelmetContent from "../../Components/Common/HelmetData";
import ApiPath from "../../Components/Common/Apiurl";

const ChanelPartner = () => {
  useEffect(() => {
    document.title = "Chanel Partner - Think Quotient";
    AOS.init();

  }, []);

  const [formVisible, setFormVisible] = useState(true);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required")
    .transform((value) => value.trim()) 
    ,
    email: Yup.string()
      .transform((value) => value.trim())
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string(),
      // .matches(/^\d{10}$/, "Invalid phone number"),
      // .required("Phone is required"),
    message: Yup.string()
    // .required("Message is required")
    .transform((value) => value.trim()),
  });

  const formik = useFormik({
    initialValues: {
      
      queryPageIndex: "CHANELPARTNER",
      lookingFor: "CHANELPARTNER",
      
      name: "",
      email: "",
      phone: "",
      message: ""
    
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const apiEndpoint =
      ApiPath+"/tqenquiry"
  
      try {
        // Call the API using fetch
        const response = await fetch(apiEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });
  
        if (response.ok) {
          
          setFormVisible(false);
          setTimeout(() => {
            setFormVisible(true);
            formik.resetForm();
          }, 3000);
        } else {
          // Handle error
          console.error("Error submitting the form");
          // Add your desired logic (e.g., show an error message)
        }
      } catch (error) {
        // Handle error
        console.error("Error submitting the form", error);
        // Add your desired logic (e.g., show an error message)
      }
    },
  });
  

  const handleFormVisibilityChange = () => {
    setFormVisible(!formVisible);
  };

  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();

  return (
    
    <>
      <Helmet>
        
        <meta name="title" content={metaTitle} />
        {/* Remaining meta tag */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}
        
        {/* scipt for facebook */}
        {helmetContent.script}
        <script>
          {`
          (function (b, m, h, a, g) {
            b[a] = b[a] || [];
            b[a].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var k = m.getElementsByTagName(h)[0],
              e = m.createElement(h),
              c = a != "dataLayer" ? "&l=" + a : "";
            e.async = true;
            e.src = "https://www.googletagmanager.com/gtm.js?id=" + g + c;
            k.parentNode.insertBefore(e, k);
          })(window, document, "script", "dataLayer", "GTM-TN6B4BK");
          `
          }
        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>,

        <script>
          {
            `
      window.dataLayer = window.dataLayer || []; 
      function gtag() { 
        dataLayer.push(arguments) 
      } gtag("js", new Date()); gtag("config", "UA-143016865-1");
       `}
        </script>,

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>,

        <script>
          {`

        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments)}
        gtag('js', new Date());

        gtag('config', 'G-BSFPPN5E9D');
        `}

        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>,

        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments) }
      gtag('js', new Date());

      gtag('config', 'AW-574047969');
      `}

        </script>
        
      </Helmet>
    <div className="overflow-hidden">
      {/* Header Section */}
      <Header />

      {/* Banner Section */}
      <div>
        <div className="chanelpartner-banner-sec flex items-center">
          <div className="container text-left text-white mx-auto">
            <div className="md:w-1/2 mx-4 md:mx-0"
            data-aos="fade-up"
            data-aos-duration="1000">
              <h1 className="text-2xl md:text-3xl font-semibold mb-4 ">
                A Growing Organization with a Proven Model
              </h1>
              <p className="text-lg mb-8">
                For every student or IT company, you refer You will benefit from
                a referral Amount
              </p>
              <Link to="/contact?scrollTo=contact-form">
                <button className="btn-style-2 px-6 py-2 ">
                  Get In Touch With Us
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* <div>
        <div className="container mx-auto md:py-20 py-10">
          <div className="text-black text-2xl md:text-5xl font-bold text-center md:w-1/2 mx-auto">
            Be a Part of ThinkQuotient <br />
            <span className="about-us-banner-txt-3 "> Franchise Network </span>
          </div>
        </div>
      </div> */}

      <div className="flex flex-col items-center py-14  bg-gray-100 fade-in-down px-6">
        <h1 className="text-lg font-normal container mx-auto text-center" 
        data-aos="fade-right"
        data-aos-duration="1000">
          <div>
            <p className="font-bold md:text-2xl text-xl">
              we believe in the power of synergy for shared growth and triumph.
              Together, we form a vibrant community built on collaboration,
              innovation, and progress.
            </p>
          </div>
          <br />
          As our partner, you play a pivotal role in shaping the future of
          education and industry interaction. Let's embark on this journey
          together, harnessing the collective strength of our network to create
          a brighter tomorrow. We invite interested parties to Collaborate as
          our Channel Partner and become part of our success story.
        </h1>
      </div>

      <div>
        <div className="container mx-auto py-16"
        data-aos="fade-up"
        data-aos-duration="1000">
          <div className="text-black md:text-3xl text-xl font-bold text-center">
            Reasons to be Our Channel Partner
          </div>
          <div className="md:flex justify-evenly py-10">
            <div className="counter-item text-center">
              <div className="h-36 flex items-center justify-center ">
                <img src="/assets/images/highi.png" className=" w-16" />
              </div>
              <p className="font-bold text-lg  mx-auto ">
                High-profit, high-rewards
              </p>
            </div>

            <div className="counter-item text-center">
              <div className="h-36 flex items-center justify-center ">
                <img src="/assets/images/no-money.png" className=" w-16 " />
              </div>
              <p className="font-bold text-lg mx-auto ">No investment</p>
            </div>

            <div className="counter-item text-center">
              <div className="h-36 flex items-center justify-center ">
                <img src="/assets/images/q&cr.png" className=" w-16 " />
              </div>
              <p className="font-bold text-lg mx-auto ">Quick Returns</p>
            </div>

            <div className="counter-item text-center">
              <div className="h-36 flex items-center justify-center ">
                <img src="/assets/images/pandemicp.png" className=" w-16 " />
              </div>
              <p className="font-bold text-lg mx-auto ">Pandemic Proof</p>
            </div>
          </div>
        </div>
      </div>

      {/* text and image box */}

      {/* First */}
      <div className="m-0 md:flex  service-boxes mb-10">
        <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center"
        data-aos="fade-right"
        data-aos-duration="1000">
          <h1 className="text-2xl font-bold">Channel Partner Vision</h1>
          <p className="mt-4 w-[80%]">
            To Build a Channel Partner network Pan India
          </p>
          <p className="mt-4 w-[80%] list-items ps-4">
            <span className="font-bold">FY 2023-24:</span>Wish to outsource the
            Student Admission process to Channel Partners across India
          </p>
          <p className="mt-6 w-[80%] list-items ps-4 ">
            <span className="font-bold">FY 2024-25:</span> Wish to outsource
            the Placement process to Channel Partners across India
          </p>
        </div>
        <div className="md:w-1/2" 
        data-aos="fade-left"
        data-aos-duration="1000">
          <img
            src="/assets/images/chanel-p2.jpg"
            className="object-cover h-full w-full"
          />
        </div>
      </div>

      <TestimonialsandBlogSec />

      <ClientLogoCarousel />
   
      <section className="relative z-10 overflow-hidden bg-white py-20 dark:bg-dark lg:py-20 mx-auto ">
        <div className="container mx-auto">
          <div className="mx-4 flex flex-wrap lg:justify-between md:mx-0">
            <div className="w-full px-4 lg:w-1/2 xl:w-6/12">
              <div className="mb-12 max-w-[570px] lg:mb-0 flex flex-col items-start justify-center h-full">
                <h2 className="text-start mb-6 md:text-[32px] text-[24px] font-bold uppercase text-dark  sm:text-[40px] lg:text-[36px] xl:text-[40px]">
                  Be a Channel Partner
                </h2>
                <p className="font-normal md:text-lg text-justify">
                Harness the power of our network for mutual growth and success.
Rewards are subject to the referred student's admission or successful placement.
Foster community, collaboration, and advancement through this partnership.
Join us in shaping the future of education and industry interaction.

We invite interested parties to Collaborate as our Channel Partner and become part of our success story.
                </p>

                <div className="cube"></div>
                <div className="cube"></div>
                <div className="cube"></div>
                <div className="cube"></div>
                <div className="cube"></div>
                <div className="cube"></div>
              </div>
            </div>
            <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
              <div className="relative rounded-lg bg-white p-8 shadow-lg dark:bg-dark-2 sm:p-12 offering-form">
                {formVisible && (
                  <form onSubmit={formik.handleSubmit}>
                    <div className="mb-6">
                      <input
                        type="text"
                        placeholder="Your Name *"
                        name="name"
                        className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      {formik.errors.name && formik.touched.name && (
                        <p className="text-red-500 mt-2">
                          {formik.errors.name}
                        </p>
                      )}
                    </div>

                    <div className="mb-6">
                      <input
                        type="text"
                        placeholder="Your Email *"
                        name="email"
                        className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <p className="text-red-500 mt-2">
                          {formik.errors.email}
                        </p>
                      )}
                    </div>

                    <div className="mb-6">
                      <input
                        type="text"
                        placeholder="Your Phone"
                        name="phone"
                        className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                        maxLength={10}
                      />
                      {formik.errors.phone && formik.touched.phone && formik.values.phone.trim().length > 0 && (
                        <p className="text-red-500 mt-2">
                          {formik.errors.phone}
                        </p>
                      )}
                    </div>

                    <div className="mb-6">
                      <textarea
                        rows="6"
                        placeholder="Your Message"
                        name="message"
                        className="w-full resize-none rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.message}
                      />
                      {formik.errors.message && formik.touched.message && (
                        <p className="text-red-500 mt-2">
                          {formik.errors.message}
                        </p>
                      )}
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="btn-style-1 w-full rounded border border-primary bg-primary p-3 text-dark transition hover:bg-opacity-90"
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                )}
                {!formVisible && (
                  
                  <div className=" 2xl:right-20 right-5 p-8 fade-in-right ">
                    <h2 className="text-2xl font-semibold mb-4">Thank You!</h2>
                    <p className="text-lg mb-4">
                      Thank you for contacting us. We'll get back to you soon.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
    </>
  );
};

export default ChanelPartner;

const ContactTextArea = ({ row, placeholder, name, defaultValue }) => {
  return (
    <>
      <div className="mb-6">
        <textarea
          rows={row}
          placeholder={placeholder}
          name={name}
          className="w-full resize-none rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
          defaultValue={defaultValue}
        />
      </div>
    </>
  );
};

const ContactInputBox = ({ type, placeholder, name }) => {
  return (
    <>
      <div className="mb-6">
        <input
          type={type}
          placeholder={placeholder}
          name={name}
          className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
        />
      </div>
    </>
  );
};
