import React from "react";
import { Helmet } from "react-helmet";

const getHelmetContent = () => {
  return {

    meta: [

      { name: "keywords", content: "Expert in Project OutSourcing and Technology Consulting" },
      { name: "description", content: "Expert in Project OutSourcing and Technology Consulting" },
      { name: "viewport", content: "width=device-width, initial-scale=1.0" },
      { name: "author", content: "Excite Template" },
      { name: "language", content: "English" },
    ],

    script: [
      <script type="application/ld+json">
        {`
            {
              "@context": "http://www.schema.org",
              "@type": "ProfessionalService",
              "name": " Professional Training Institute in Pune",
              "url": "https://www.thinkquotient.com/",
              "logo": "https://www.thinkquotient.com/assets/images/envelop.png",
              "image": "https://thinkquotient.com/assets/images/envelop.png",
              "description": "Expert in Project OutSourcing and Technology Consulting",
              "telephone": "+917030055128,
              "address": {
              "@type": "PostalAddress",
              "streetAddress": "Ground Floor, 5/3, Prashant Apartments, Karve Rd, behind Telephone Exchange, Nal Stop, Pandurang Colony, Erandwane",
              "addressLocality": "Pune",
              "addressRegion": "Maharashtra",
              "postalCode": "411004",
              "addressCountry": "India"
              },
              "geo": {
              "@type": "GeoCoordinates",
              "latitude": "18.5074803",
              "longitude": "73.8300766"
              },
              "sameAs" : [ "https://www.facebook.com/ThinkQuotient-Software-Pvt-Ltd-425080191407703/",
              "https://twitter.com/ThinkQuotient",
              " https://www.linkedin.com/company/thinkquotient/",
              "https://www.instagram.com/thinkquotient_software/?igshid=1j31daue8ovci"]

            }
          `}
      </script>
    ],

    gTagScript: [
      <script>
        {`
          (function (b, m, h, a, g) {
            b[a] = b[a] || [];
            b[a].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var k = m.getElementsByTagName(h)[0],
              e = m.createElement(h),
              c = a != "dataLayer" ? "&l=" + a : "";
            e.async = true;
            e.src = "https://www.googletagmanager.com/gtm.js?id=" + g + c;
            k.parentNode.insertBefore(e, k);
          })(window, document, "script", "dataLayer", "GTM-TN6B4BK");
          `
        }
      </script>,
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>,

      <script>
        {
          `
      window.dataLayer = window.dataLayer || []; 
      function gtag() { 
        dataLayer.push(arguments) 
      } gtag("js", new Date()); gtag("config", "UA-143016865-1");
       `}
      </script>,

      <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>,

      <script>
        {`

        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments)}
        gtag('js', new Date());

        gtag('config', 'G-BSFPPN5E9D');
        `}

      </script>,
    <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>,

    <script>
     {`
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments) }
      gtag('js', new Date());

      gtag('config', 'AW-574047969');
      `}

    </script>


    ]




    // 



    


  }
}


export default getHelmetContent;