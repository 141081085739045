import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SuccessStoryCarousel = () => {



  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Set slidesToShow to 1 for centerMode
    slidesToScroll: 1,
    // autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 2000,
    centerMode: true, // Enable centerMode
    centerPadding: '0', // Adjust centerPadding as needed
    
    
    responsive: [
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          
        },
      },
      {
        breakpoint: 1024,
        settings: { 
          slidesToShow: 4,
        },
      },
    ],
    prevArrow: (
      <button type="button" className="slick-arrow slick-prev">
        Left &lt;
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-arrow slick-next">
        Right &gt;
      </button>
    ),
  };
  

  return (

    <div className="py-10 px-4 md:px-0">
      <div className="container mx-auto text-center justify-center mb-10">
        <h1 className="capitalize text-center sm:col-span-2 lg:col-span-4 text-3xl sm:text-5xl xl:text-3xl font-bold pb-5">
          Success Story
        </h1>

        <Slider {...settings}>
          <div className="box-na mobile-box">
            <iframe width="250" height="315" src="https://www.youtube.com/embed/cS-4QcuouT0?si=i_sRQO95YPWtLn39" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
          <div className="box-na mobile-box">
            <iframe width="250" height="315" src="https://www.youtube.com/embed/NGNlHwtqSAw?si=GEWHMNjZjS1GrwBI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
          <div className="box-na mobile-box">
            <iframe width="250" height="315" src="https://www.youtube.com/embed/fSQGw5toqWc?si=t36ZOUthY1LcPnYX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
          <div className="box-na mobile-box">
            <iframe width="250" height="315" src="https://www.youtube.com/embed/_u5NCbznIK0?si=oJjYbqfhF-7s65E_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
          <div className="box-na mobile-box">
            <iframe width="250" height="315" src="https://www.youtube.com/embed/0tzQkxY5juQ?si=lfdjBhq4yKzq_m7T" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>




        </Slider>
      </div>
    </div>


  );
};

export default SuccessStoryCarousel;
