import React from "react";
import Modal from "react-modal";




const ImageModal = ({ isOpen, onClose, imageUrl }) => {

  // const download = () => {
    
  //     const link = document.createElement("a");
  //     link.href = imageUrl;
  //     link.download = imageUrl.split("/").pop();
  //     link.click();
    
  // };

  return (
    <div className={`custom-modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <button className="modal-close-btn" onClick={onClose}>
          X
        </button>
        <img src={imageUrl} alt="gallery-image" className="modal-image"/>
        {/* <div className="conatiner text-center  mt-3">
          <button
              className="btn-style-1 px-6 py-2 sm:text-sm md:w-1/4"
              onClick={() => download()}
            >
              Download 
            </button>
          </div> */}
      </div>
    </div>
  );
};

export default ImageModal;













// import React from "react";
// import Modal from "react-modal";


// Modal.setAppElement("#root");

// const ImageModal = ({ isOpen, onClose, imageUrl }) => {
//   return (
//     <Modal
//       isOpen={isOpen}
//       onRequestClose={onClose}
//       contentLabel="Image Modal"
//       className="image-modal "
//       overlayClassName="image-modal-overlay"
//     >
//       <button className="modal-close-btn" onClick={onClose}>
//         X
//       </button>
//       <img src={imageUrl} alt="gallery-image" className="modal-image"  style={{height:500}} />
//     </Modal>
//   );
// };

// export default ImageModal;

