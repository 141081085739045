import Header from "../../Components/Layout/Header";
import ClientLogoCarousel from "../../Components/Home/ClientLogoCarousel";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import TestimonialsandBlogSec from "../../Components/Home/TestimonialsandBlogSec";
import WhyUs from "../../Components/Home/WhyUs";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";
import getHelmetContent from "../../Components/Common/HelmetData";
import { Helmet } from "react-helmet";

const ExpectationOfHiring = () => {
  useEffect(() => {
    document.title = "CSR - Think Quotient";
    AOS.init();

  }, []);
  const blogData = [
    {
      id: 1,
      image: "/assets/images/csr-1.jpg",
      button: "Read More",
      blogHeading: "Free Job Assistance Drive for Physically Challenged",
      name: "- November 10, 2021",
      link: "https://thinkquotientblogs.blogspot.com/2021/11/why-are-it-companies-hiring-more_10.html",
      text: " We train the eligible candidates FREE OF COST on Full Stack Development. On completion of the course, we send students for placement drive in various ITcompanies. ThinkQuotient feels privileged to be able to help, assist & guide physically challenged people towards getting a good job and means to live a good life. We will continue to assist them in every possible way at ThinkQuotient.",
    },
    {
      id: 2,
      image: "/assets/images/csr-2.jpg",
      button: "Read More",
      blogHeading: "Flood relief Kolhapur-Sangli",
      name: "- July 04, 2021 ",
      link: "https://thinkquotientblogs.blogspot.com/2021/07/covid-19-impact-on-it-industry.html",
      text: "We at ThinkQuotient extended a helping hand by providing monetary help to the victims of flood-affected areas of Kolhapur- Sangli in August 2018 through RISO Foundation. The heavy rains washed away most of the homes and sources of livelihood. Each one of our employee gave their contribution to uplift these flood-hit areas. ThinkQuotient is very proud of every employee who contributed for these people. ",
    },
    {
      id: 3,
      image: "/assets/images/csr-3.jpg",
      button: "Read More",
      blogHeading: "Clothes Donation",
      name: "- July 04, 2021 ",
      link: "https://thinkquotientblogs.blogspot.com/2021/07/covid-19-impact-on-it-industry.html",
      text: "Many are less fortunate than us when it comes to the necessities of life. Many are deprived of food, clothes, shelter, and education, which often drifts them towards a saddening lifestyle. Knowing this fact, we organized a clothes donation drive where our employees got clothes that were donated to RISO Foundation. Every employee of ThinkQuotient actively participated in this drive and contributed generously. ",
    },
  ];

  const blogsRef = useRef(null);

  const blogsSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    beforeChange: (_, next) => setActiveBlog(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const goToBlogSlide = (index) => {
    blogsRef.current.slickGoTo(index);
  };

  const [activeBlog, setActiveBlog] = useState(0);
  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();

  return (

    <>
      <Helmet>

        <meta name="title" content={metaTitle} />
        {/* Remaining meta tag */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* scipt for facebook */}
        {helmetContent.script}
        <script>
          {`
          (function (b, m, h, a, g) {
            b[a] = b[a] || [];
            b[a].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var k = m.getElementsByTagName(h)[0],
              e = m.createElement(h),
              c = a != "dataLayer" ? "&l=" + a : "";
            e.async = true;
            e.src = "https://www.googletagmanager.com/gtm.js?id=" + g + c;
            k.parentNode.insertBefore(e, k);
          })(window, document, "script", "dataLayer", "GTM-TN6B4BK");
          `
          }
        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>,

        <script>
          {
            `
      window.dataLayer = window.dataLayer || []; 
      function gtag() { 
        dataLayer.push(arguments) 
      } gtag("js", new Date()); gtag("config", "UA-143016865-1");
       `}
        </script>,

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>,

        <script>
          {`

        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments)}
        gtag('js', new Date());

        gtag('config', 'G-BSFPPN5E9D');
        `}

        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>,

        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments) }
      gtag('js', new Date());

      gtag('config', 'AW-574047969');
      `}

        </script>

      </Helmet>
      <div className="overflow-hidden">
        {/* Header Section */}
        <Header />



        {/* First */}

        <div className="m-0 md:flex  service-boxes container md:mx-auto p-6 md:p-0">

          <div className="md:w-1/2   flex flex-col justify-center">
            <h1 className="text-4xl font-bold mb-4">Expectations of hiring from market</h1>
            {/* <p className="mt-4 w-[80%]">To Build a Franchise network Pan India.</p> */}
            <div className="flex items-center"
              data-aos="fade-right"
              data-aos-duration="1000">
              <div>
                <p className="mt-4  list-items ps-4 text-justify">
                  A lot of Engineering Graduates have been looking for a suitable opportunity to start their
                  IT career. As compared to 2023, we have seen a considerable decline in the hiring of IT
                  fresh graduates in 2024. We highlight the pre-requisites every fresher must have in
                  order to become employable. <br />
                  The current IT market is unforeseeable. From good communication skills, having the
                  right technical knowledge, attitude and confidence is what each and every organization
                  is looking for in a candidate to be hired. Commitment is another key aspect where the
                  companies are finding it difficult to hire the resource. Even the smallest and the growing
                  organizations are evaluating the candidates after conducting 3 to 4rounds of interviews.
                  In some cases, employment is given to the local candidates as the chances of leaving
                  the organizations are less.A noutstation candidate will have to adapt to the new culture,
                  new city, new people which not necessary will continue for a long period. Some of the
                  employers are paying too less at the beginning of the career as an intern and once the
                  candidate performs well, they are hired as a full-time employee. </p>
              </div>
            </div>
          </div>
          {/* Right Section */}
          <div className="md:w-1/2  flex justify-end items-center fade-in-right space-x-8"
            data-aos="fade-left"
            data-aos-duration="1000">
            <img
              src="/assets/images/hirring2.jpg"
              alt="Image 2"
              className="md:w-4/5 object-cover rounded-md md:mt-28 mt-10 border shadow-md"
            />
          </div>
        </div>


        <div className="m-0 md:flex service-boxes container md:mx-auto p-6 md:p-0">
          {/* Left Section: Image */}
          <div className="md:w-1/2 flex justify-start items-center fade-in-right space-x-8"
            data-aos="fade-right"
            data-aos-duration="1000">
            <img
              src="/assets/images/hirring1.jpg"  // Use a different image for variety
              alt="Image 2"
              className="md:w-4/5 object-cover rounded-md md:mt-28 mt-10 border shadow-md"
            />
          </div>

          {/* Right Section: Text */}
          <div className="md:w-1/2 flex flex-col justify-center mt-6">
            <h1 className="text-4xl font-bold mb-4">Skills Required for IT Fresh Graduates</h1>
            <div className="flex items-center"
              data-aos="fade-left"
              data-aos-duration="1000">
              <div>
                <p className="mt-4 list-items ps-4 text-justify">
                  From the overall IT graduates who passout every year it is observed that only selective
                  percentage of candidates possess the necessary skills in order to get placed.
                  Employers focus on the basic prerequisite which is the basic technical concepts in
                  which most of the candidates fail and they even do not take the necessary steps to be
                  perfect.A candidate may be an old passout which is 2016 or 2018, but having the right skills
                  required for the job and a proper justification to the gaps will surely help the candidate
                  get selected.<br />
                  Many big companies now a days have started hiring even from tier 2, tier 3 colleges,
                  just their focus is on good technical skills, and knowledge. The Candidate would also
                  have an advantage if they focus to work on small project assignment or an internship
                  during their last year.This will act as a catalyst to their profile and they will get a chance
                  to showcase their work.<br /> <br />
                  Having said that, a good market will always desire to have a resource well-equipped
                  with the required skills all the time. Many of you can also develop the skills under the
                  guidance of our mentors who have professionally groomed many aspiring IT freshers.


                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=" container p-10">
           <div className="text-right">
            <h2 className="text-3xl font-bold mb-2">Priya Pande</h2>
            <h3 className="text-xl font-semibold mb-2">Recruitment Manager</h3>
            </div>
        </div>


        {/*        

      

        <TestimonialsandBlogSec />
        <ClientLogoCarousel /> */}

        <Footer />
      </div>
    </>
  );
};

export default ExpectationOfHiring;
