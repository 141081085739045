import React, { useEffect, useState } from "react";
import Header from "../../Components/Layout/Header";
import ClientLogoCarousel from "../../Components/Home/ClientLogoCarousel";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import TestimonialsandBlogSec from "../../Components/Home/TestimonialsandBlogSec";
import WhyUs from "../../Components/Home/WhyUs";
import AOS from "aos";
import "aos/dist/aos.css";
import FormModal from "../../Components/Home/FormModal";
import { Helmet } from "react-helmet";
import getHelmetContent from "../../Components/Common/HelmetData";

const BusinessDevelopment = () => {
  useEffect(() => {
    document.title = "Career - Think Quotient";
    AOS.init();

  }, []);

  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = (data) => {
     setModalData(data);
    setModalOpen(true);
  };
  const openModalOne = (data) => {
   

    setModalData(data);
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalData(null);
    setModalOpen(false);
  };

  

  // const pageTitle = "Salary based employment";
  const metaTitle = "Expert in Project OutSourcing and Technology Consulting";
  const helmetContent = getHelmetContent();
  return (

    <>
      <Helmet>
        {/* <title>{pageTitle}</title> */}
        <meta name="title" content={metaTitle} />
        {/* Remaining meta tag */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* scipt for facebook */}
        {helmetContent.script}
        <script>
          {`
          (function (b, m, h, a, g) {
            b[a] = b[a] || [];
            b[a].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var k = m.getElementsByTagName(h)[0],
              e = m.createElement(h),
              c = a != "dataLayer" ? "&l=" + a : "";
            e.async = true;
            e.src = "https://www.googletagmanager.com/gtm.js?id=" + g + c;
            k.parentNode.insertBefore(e, k);
          })(window, document, "script", "dataLayer", "GTM-TN6B4BK");
          `
          }
        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>,

        <script>
          {
            `
      window.dataLayer = window.dataLayer || []; 
      function gtag() { 
        dataLayer.push(arguments) 
      } gtag("js", new Date()); gtag("config", "UA-143016865-1");
       `}
        </script>,

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>,

        <script>
          {`

        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments)}
        gtag('js', new Date());

        gtag('config', 'G-BSFPPN5E9D');
        `}

        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>,

        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments) }
      gtag('js', new Date());

      gtag('config', 'AW-574047969');
      `}

        </script>

      </Helmet>
      <div>
        {/* Header Section */}
        <Header />

        {/* Banner Section */}
        <div>
          <div className="technical-banner-sec flex items-center">
            <div className="container text-left text-white mx-auto">
              <div className="md:w-1/2 mx-4 md:mx-0"
                data-aos="fade-up"
                data-aos-duration="1000">
                <h1 className="text-2xl md:text-6xl font-semibold mb-2 ">
                  Find Your Ideal Job at ThinkQuotient
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center py-14  bg-gray-100 fade-in-down px-6">
          <h1 className="text-4xl font-bold container mx-auto text-center"
            data-aos="fade-up"
            data-aos-duration="1000">
            Current Openings
          </h1>
        </div>




        {/* Business Development*/}
        <div className=" bg-gray-100">
          <div className="container mx-auto flex flex-col justify-center px-2 py-20"
            data-aos="fade-up"
            data-aos-duration="1000">
            <h1 className="text-2xl font-bold">Business Development:</h1>
            <p className="mt-4 md:w-[80%] list-items ps-6">
              Job Description- Business Development Officer
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 ">Salary- 3-3.6 LPA depending on Interview performance</p>
            <p className="mt-4 md:w-[80%] list-items ps-6 ">
              Notice Period - 2 Months
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 ">
              Probation Period- 3 Months
            </p>


            {/* <p className="md:w-[80%]  my-6 font-semibold text-lg">Job Summary</p>
          <p className="w-11/12">
            The Placement Team Resource plays a crucial role in supporting the
            placement team's efforts to connect candidates with suitable
            employment opportunities. This position involves a combination of
            administrative tasks, relationship management, and communication
            skills to ensure a seamless placement process.
          </p> */}
            <p className="md:w-[80%]  mt-6 font-semibold text-lg">
              Job Description
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Plan and execute marketing and business development activities.
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Maintain records of calls, sales, and customer information.
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Strengthen market presence and meet revenue targets.
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Implement marketing strategies for organizational growth.
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Research market trends and identify business opportunities.
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Propose and implement improvements to existing strategies.
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Collaborate with company management and suggest process enhancements.
            </p>


            <p className="mt-4 md:w-[80%] mb-2 font-semibold text-lg">
              Required Qualification and Skills
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Should have Basic Computer Knowledge.
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Should have Excellent Communication and Interpersonal Skills
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Should possess excellent Convincing Power.
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Fluent in English, Marathi, and Hindi
            </p>



            <div className="w-full flex justify-center">

              <button className="btn-style-3 px-6 py-2 mt-8 " onClick={() => openModal("Business Development")}>
              Upload Resume →
              </button>

            </div>
            <FormModal isOpen={isModalOpen} onRequestClose={closeModal} initialData={modalData} />
          </div>
        </div>

        {/* Technical Trainer*/}
        <div className="container mx-auto border-b mb-10"
          data-aos="fade-up"
          data-aos-duration="1000">
          <div className="flex flex-col justify-center px-2 py-20">
            <h1 className="text-2xl font-bold">Technical Trainer:</h1>

            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
             <b>Education- </b> <br /> UG: B.Tech/B.E.-Computers,BCA -Computers,B.Sc- Computers.   <br /> PG: M.Tech-Computers,MCA-Computers,MS/M.Sc(Science)-Computers    </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
            </p>

            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
            <b>Key Skills-  </b> <br />
            Core Java, Advanced Java, Angular, Spring Boot, Java Trainer, BI/Data Analytics, Software Trainer, IT Training
   <br />
              
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
             <b>Location-</b>  Pune<br />
            </p>


            <p className="mt-4 md:w-[80%] mb-2 font-semibold text-lg">
              Industry Type
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Education/Teaching/Training      </p>



            {/* <p className="md:w-[80%]  my-6 font-semibold text-lg">Job Summary</p>
          <p className="w-11/12">
            The Placement Team Resource plays a crucial role in supporting the
            placement team's efforts to connect candidates with suitable
            employment opportunities. This position involves a combination of
            administrative tasks, relationship management, and communication
            skills to ensure a seamless placement process.
          </p> */}
            <p className="md:w-[80%]  mt-6 font-semibold text-lg">
              Job Description
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Conduct classroom sessions (lectures & practical) as per the curriculum.
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Analyze & generatere ports for each student's progress.
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Prepare and assesstest papers.
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Prepare technical contents.
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Resolve students' queries and ensure concepts are understood/implemented correct.
            </p>



            <p className="mt-4 md:w-[80%] mb-2 font-semibold text-lg">
              Required Candidate profile
            </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Expert in Core Java.         </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Should possess excellent communication skills.          </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Good to have knowledge about Spring framework, Angular, React JS.          </p>
            <p className="mt-4 md:w-[80%] list-items ps-6 mb-2">
              Should be ready to learn new technologies and willing to upgrade skills.          </p>
            <div className="w-full flex justify-center">
              <button className="btn-style-3 px-6 py-2 mt-8 " onClick={() => openModal("Technical Trainer")}>
              Upload Resume →
              </button>
              
            </div>
          </div>
        </div>

        <TestimonialsandBlogSec />
        <WhyUs />
        <ClientLogoCarousel />
        <Footer />
      </div>
      </>
      );
};

      export default BusinessDevelopment;
