import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import { FaFacebook, FaFacebookF, FaInstagram, FaLinkedin, FaLinkedinIn, FaYoutube } from "react-icons/fa";

const HeaderCampaign = () => {
  const location = useLocation();
  const [isSticky, setSticky] = useState(false);
  const [isServicesOpen, setServicesOpen] = useState(false);
  const [isProductsOpen, setProductsOpen] = useState(false);
  const [isInsightsOpen, setInsightsOpen] = useState(false);
  const [isOfferingsOpen, setOfferingsOpen] = useState(false);
  const [isCareersOpen, setCareersOpen] = useState(false);





  const handleClick = () => {
    // Check if the current location is the home page
    console.log(location.pathname);
    if (location.pathname === '/' || location.pathname === '/contact') {
      // Scroll to the top of the page
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    // For other pages, the Link component will handle the navigation
  };

  const toggleProducts = () => {
    setProductsOpen(!isProductsOpen);
  };

  const toggleServices = () => {
    setServicesOpen(!isServicesOpen);
  };

  const toggleInsights = () => {
    setInsightsOpen(!isInsightsOpen);
  };

  const toggleOfferings = () => {
    setOfferingsOpen(!isOfferingsOpen);
  };

  const toggleCareers = () => {
    setCareersOpen(!isCareersOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setSticky(offset > 400);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isActiveRoute = (path) => {
    return location.pathname === path;
  };

  // Mobile menu
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div
      className={`w-full z-50 bg-white shadow-sm  ${isSticky ? "sticky-header slide-in-top" : ""
        }`}
    >
      {/* Large Screen Navbar */}
      <div className="bg-black text-white py-2 px-4 main-nav">
        <div className="container mx-auto flex items-center justify-center md:justify-between">
          <div className="flex items-center justify-between space-x-1">
            <p className="md:placeholder:mr-4 md:text-sm text-xs">
              Call:{" "}
              <a href="tel:+917030055128" className="header-contact-hover-1">
                +91-7030055128
              </a>
            </p>
            <p className="md:mr-4 ">|</p>
            <p className="mr-4 md:text-sm text-xs">
              Email:{" "}


              <a
                href="mailto:support@thinkquotient.com"
                className="header-contact-hover-2"
              >
                support@thinkquotient.com
              </a>
            </p>
          </div>
          <div className="md:flex items-center  hidden">
            {/* <span className="mr-4">
              <a href="https://www.instagram.com/thinkquotient_software/" target="_blank"><img
                src="/assets/images/insta.svg"
                alt="Instagram"
                className="skyblue-image hover1"
              /></a>
            </span>
            <span className="mr-4">
              <a href="https://www.linkedin.com/company/thinkquotient/mycompany/?viewAsMember=true" target="_blank"><img
                src="/assets/images/linkedin.svg"
                alt="Twitter"
                className="skyblue-image w-6 hover2"
              /></a>
            </span>
            <span className="mr-4">
              <a href=" https://www.youtube.com/@thinkquotient1429" target="_blank"><img
                src="/assets/images/youtube.svg"
                alt="Youtube"
                className="skyblue-image w-6 hover3"
              /></a>
            </span>
            <a href=" https://www.facebook.com/ThinkQuotient" target="_blank"><img
              src="/assets/images/facebook.svg"
              alt="Facebook"
              className="skyblue-image hover4"
            /></a> */}

            <span className="mr-4">
              <a href="https://www.instagram.com/thinkquotient_software/" target="_blank">
                <FaInstagram className="skyblue-image" />
              </a>
            </span>
            <span className="mr-4">
              <a href="https://www.linkedin.com/company/thinkquotient?originalSubdomain=in" target="_blank">
                <FaLinkedinIn className="skyblue-image" />
              </a>
            </span>
            <span className="mr-4">
              <a href=" https://www.youtube.com/@thinkquotient1429" target="_blank">
                <FaYoutube className="skyblue-image" />
              </a>
            </span>
            <span className="mr-4">
              <a href=" https://www.facebook.com/ThinkQuotient" target="_blank">
                <FaFacebookF className="skyblue-image" />
              </a>
            </span>
          </div>
        </div>
      </div>

      <div className="container mx-auto flex items-center justify-between py-4 px-4 md:px-0 main-nav">
        <div className="flex items-center">
          <Link to="/">
            <img
              src="/assets/images/envelop.png"
              alt="Main Logo"
              className="md:w-full object-cover h-14 mr-4"
              onClick={handleClick}
            />
          </Link>
        </div>
      

        {/* Mobile Navbar */}
       
      
      </div>
      {/* Large Screen Navbar Ends */}
    </div>
  );
};

export default HeaderCampaign;
