import React, { useState } from 'react';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import * as Yup from "yup";
import ApiPath from '../Common/Apiurl';


const validFileExtensions = {
    document: ['pdf', 'doc', 'docx']
  };
  
  function isValidFileType(fileName, fileType) {
    return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop().toLowerCase()) > -1;
  }

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .transform((value) => value.trim())
        .required("Name is required"),
    email: Yup.string()
        .transform((value) => value.trim())
        .email("Invalid email address")
        .required("Email is required"),
    phone: Yup.string()
        .transform((value) => value.trim())
        .matches(/^\d{10}$/, "Invalid phone number"),
        // .required("Phone is required"),
    // jobProfile: Yup.string()
    //     .transform((value) => value.trim())
    //     .required("Message is required"),
    resume: Yup
    .mixed()
    .required("Required")
    .test("is-valid-type", "Not a valid file type",
      value => isValidFileType(value && value.name.toLowerCase(), "document"))
    
});
        


const FormModal = ({ isOpen, onRequestClose,initialData })=> {
    console.log("initialData");
console.log(initialData)
    const [formVisible, setFormVisible] = useState(true);
    const apiEndpoint =
        ApiPath + "/saveResumeForm";
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
           
            resume: null, // Use null for the file
        },



        validationSchema: validationSchema, // Include your validation schema
        onSubmit: async (values) => {
            console.log(apiEndpoint);
            try {
                const formData = new FormData();
                formData.append('fullName', values.name);
                formData.append('email', values.email.trim());
                formData.append('phone', values.phone);
                formData.append('jobProfile', initialData);
                formData.append('file', values.resume);

                // Make your API request with formData
                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    body: formData,
                });

                // Handle the response accordingly
                if (response.status == 200) {
                    formik.resetForm();
                   

                    setFormVisible(false);
                    setTimeout(() => {
                        setFormVisible(true);
                        handleModalClose()
                    }, 4000);

                    
                }

            } catch (error) {
                console.error('Error submitting form:', error);
            }
        },
    });

    const handleModalClose = () => {
        formik.resetForm();  // Reset the form on modal close
        formik.setFieldValue('resume', null);

        const fileInput = document.getElementById('resume');
    if (fileInput) {
        fileInput.value = '';
    }

    onRequestClose();  
        onRequestClose();    // Close the modal
    };


    return (
        <div className={`custom-modal ${isOpen ? "open" : ""}`}>
            <div className="modal-content">
                <button className="modal-close-btn" onClick={handleModalClose}>
                    X
                </button>

                <div className="relative rounded-lg bg-white p-4 sm:p-8 dark:bg-dark-2">
                {formVisible && (
                    <form onSubmit={formik.handleSubmit}>
                        <div className="mb-6">
                            <input
                                type="text"
                                placeholder="Your Name *"
                                name="name"
                                className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                            />
                            {formik.errors.name && formik.touched.name && (
                                <p className="text-red-500 mt-2">
                                    {formik.errors.name}
                                </p>
                            )}
                        </div>

                        <div className="mb-6">
                            <input
                                type="text"
                                placeholder="Your Email *"
                                name="email"
                                className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            />
                            {formik.errors.email && formik.touched.email && (
                                <p className="text-red-500 mt-2">
                                    {formik.errors.email}
                                </p>
                            )}
                        </div>

                        <div className="mb-6">
                            <input
                                type="tel"
                                placeholder="Your Phone"
                                name="phone"
                                className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone}
                                minLength={10}
                                maxLength={10}
                            
                                                            />
                            {formik.errors.phone && formik.touched.phone && formik.values.phone.trim().length > 0 && (
                                <p className="text-red-500 mt-2">
                                    {formik.errors.phone}
                                </p>
                            )}
                        </div>

                        {/* <div className="mb-6">
                            <input
                                type="text"
                                placeholder="Job Profile"
                                name="jobProfile"
                                className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.jobProfile}

                            />
                            {formik.errors.phone && formik.touched.phone && (
                                <p className="text-red-500 mt-2">
                                    {formik.errors.phone}
                                </p>
                            )}
                        </div> */}

                        <div className="mb-6">
                            <label htmlFor="resume" className="block text-sm font-medium text-gray-700">
                                Upload Resume
                            </label>
                            <input
                                type="file"
                                id="resume"
                                name="resume"
                                // onChange={(event) => formik.setFieldValue('resume', event.currentTarget.files[0])}
                                onChange={(event) => {
                                    const selectedFile = event.currentTarget.files[0];
                                
                                    // Check if the selected file has a valid extension
                                    if (selectedFile) {
                                      if (isValidFileType(selectedFile.name, "document")) {
                                        formik.setFieldValue('resume', selectedFile);
                                      } else {
                                        // Clear the file input and show an error message
                                        formik.setFieldValue('resume', null);
                                        event.target.value = null;
                                        alert('Please select a valid PDF or Word document.');
                                      }
                                    }
                                  }}
                                className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
                            />
                            {formik.errors.resume && formik.touched.resume && (
                                <p className="text-red-500 mt-2">
                                    {formik.errors.resume}
                                </p>
                            )}
                        </div>






                        <div>
                            <button
                                type="submit"
                                className="btn-style-1 w-full rounded border border-primary bg-primary p-3 text-dark transition hover:bg-opacity-90"
                            >
                                Send
                            </button>

                           
                        </div>
                    </form>
                    )}
                     {!formVisible && (
                                <div className=" 2xl:right-20 right-5 p-8 fade-in-right ">
                                    <h2 className="text-2xl font-semibold mb-4">
                                        Thank You for contacting us.!
                                    </h2>
                                    <p className="text-lg mb-4">We'll get back to you soon.</p>
                                </div>
                            )}
                </div>
            </div>
        </div>
    );
};

export default FormModal;