import React from "react";
import "./pattern.css";

const Pattern = () => {
  return (
    <div className="pattern absolute !z-[1] right-20 md:mt-20 mt-[300px] hidden md:block">
      <div className="svg">
        <svg width="896" height="131" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter id="svg_2_blur">
              <feGaussianBlur stdDeviation="2" />
            </filter>
          </defs>
          <g>
            <title>0.7734.co.uk</title>
            <rect
              x="-1"
              y="-1"
              width="898"
              height="133"
              id="canvas_background"
              fill="none"
            />
          </g>
          <g>
            <title>Layer 1</title>
            <path
              className="path  "
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="none"
              stroke-miterlimit="10"
              d="m80,18.69969l67.35714,0l21.35714,46.82143l92,-41.07143c0,0 29.09726,-13.20286 98.57143,-12.32142l66.53571,0l0,40.25l29.57143,0c0,0 15.03214,-0.73929 23,14.78571l147.85714,0c0,0 40.90714,-2.62857 105.14285,32.03572l39.42857,19.71428l45.17857,0"
              id="svg_4"
              stroke=""
              stroke-width="20"
              
            />
            <path
              className="path "
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="none"
              stroke-miterlimit="10"
              d="m80,18.69969l67.35714,0l21.35714,46.82143l92,-41.07143c0,0 29.09726,-13.20286 98.57143,-12.32142l66.53571,0l0,40.25l29.57143,0c0,0 15.03214,-0.73929 23,14.78571l147.85714,0c0,0 40.90714,-2.62857 105.14285,32.03572l39.42857,19.71428l45.17857,0"
              id="svg_3"
              stroke="#fff"
              stroke-width="3"
              
            />
            <path
              className="path  "
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="none"
              stroke-miterlimit="10"
              d="m80,18.69969l67.35714,0l21.35714,46.82143l92,-41.07143c0,0 29.09726,-13.20286 98.57143,-12.32142l66.53571,0l0,40.25l29.57143,0c0,0 15.03214,-0.73929 23,14.78571l147.85714,0c0,0 40.90714,-2.62857 105.14285,32.03572l39.42857,19.71428l45.17857,0"
              id="svg_2"
              stroke="#fff"
              stroke-width="2"
              
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="none"
              stroke-miterlimit="10"
              d="m80,18.69969l67.35714,0l21.35714,46.82143l92,-41.07143c0,0 29.09726,-13.20286 98.57143,-12.32142l66.53571,0l0,40.25l29.57143,0c0,0 15.03214,-0.73929 23,14.78571l147.85714,0c0,0 40.90714,-2.62857 105.14285,32.03572l39.42857,19.71428l45.17857,0"
              id="svg_1"
              stroke="#fff"
              stroke-width="1"
            />
          </g>
        </svg>
      </div>
      <div className="svg opt1">
        <svg width="896" height="131" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter id="svg_2_blur">
              <feGaussianBlur stdDeviation="2" />
            </filter>
          </defs>
          <g>
            <title>0.7734.co.uk</title>
            <rect
              x="-1"
              y="-1"
              width="898"
              height="133"
              id="canvas_background"
              fill="none"
            />
          </g>
          <g>
            <title>Layer 1</title>
            <path
              className="path  "
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="none"
              stroke-miterlimit="10"
              d="m80,18.69969l67.35714,0l21.35714,46.82143l92,-41.07143c0,0 29.09726,-13.20286 98.57143,-12.32142l66.53571,0l0,40.25l29.57143,0c0,0 15.03214,-0.73929 23,14.78571l147.85714,0c0,0 40.90714,-2.62857 105.14285,32.03572l39.42857,19.71428l45.17857,0"
              id="svg_4"
              stroke=""
              stroke-width="20"
              
            />
            <path
              className="path  "
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="none"
              stroke-miterlimit="10"
              d="m80,18.69969l67.35714,0l21.35714,46.82143l92,-41.07143c0,0 29.09726,-13.20286 98.57143,-12.32142l66.53571,0l0,40.25l29.57143,0c0,0 15.03214,-0.73929 23,14.78571l147.85714,0c0,0 40.90714,-2.62857 105.14285,32.03572l39.42857,19.71428l45.17857,0"
              id="svg_3"
              stroke="#fff"
              stroke-width="3"
              
            />
            <path
              className="path  "
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="none"
              stroke-miterlimit="10"
              d="m80,18.69969l67.35714,0l21.35714,46.82143l92,-41.07143c0,0 29.09726,-13.20286 98.57143,-12.32142l66.53571,0l0,40.25l29.57143,0c0,0 15.03214,-0.73929 23,14.78571l147.85714,0c0,0 40.90714,-2.62857 105.14285,32.03572l39.42857,19.71428l45.17857,0"
              id="svg_2"
              stroke="#fff"
              stroke-width="2"
              
            />
            <path
              className="path"
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="none"
              stroke-miterlimit="10"
              d="m80,18.69969l67.35714,0l21.35714,46.82143l92,-41.07143c0,0 29.09726,-13.20286 98.57143,-12.32142l66.53571,0l0,40.25l29.57143,0c0,0 15.03214,-0.73929 23,14.78571l147.85714,0c0,0 40.90714,-2.62857 105.14285,32.03572l39.42857,19.71428l45.17857,0"
              id="svg_1"
              stroke="#fff"
              stroke-width="1"
            />
          </g>
        </svg>
      </div>
  
    </div>
  );
};

export default Pattern;
