import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialsandBlogSec = () => {
  // Testimonials data
  const testimonialsData = [
    {
      id: 1,
      icon: "path/to/icon-1.png",
      // heading: 'Art Freedom and Creativity',
      text: "Pranav and his team members are extremely pronal people and they understand the main point of the customer and provide their specially designed customer centricity service through trained student on prompt basis. Students are very bright and bring Agility, skill professiciency and technical knowledge along with them. He takes a lot efforts to develop each student with personal attention during training period. ",
      name: "Avinash Kshirsagar Delivery Manager, Allianz",
      image: "/assets/images/user-2.png",
    },

    {
      id: 2,
      icon: "path/to/icon-3.png",
      // heading: 'Testimonial Heading 3',
      text: "In the past we have tried hiring from various institutes/agencies but Think Quotient towers over this crowded space owing to 2 reasons- a) Their team headed by Pranav are extremely professional and responsive to our needs and make it a pleasure to deal with them and b) Their students are a cut at the rest of TQ's competitors and are well groomed, highly trained and industry ready.We wish TQ the very best and hope for a long association with them.",
      name: "Ravishankar iyer Co-Founder, Wishtree Technologies",
      image: "/assets/images/user-1.jpg",
    },
    {
      id: 3,
      icon: "path/to/icon-4.png",
      // heading: "Testimonial Heading 4",
      text: "Think Quotient is a brain child of Pranav Brahme who has very successful and established career himself. He is a successful entrepreneur today who has engineer at its core. He has always been passionate about mentoring a core engineer in your and helping the ecosystem. He also has equally passionate staff working with him. I highly recommend his company services to businesses as it is win- win proposal. Everyone gives services, Think Quotient stands out because of its attitude, ethics and partnership. ",
      name: "Priya Tandon Head of Healthcare, TietoEVRY",
      image: "/assets/images/user-2.png",
    },
    {
      id: 4,
      icon: "path/to/icon-2.png",
      // heading: 'Testimonial Heading 2',
      text: "Company overall is really good and do have readily available candidates on various technologies available Candidates which they provide really good technically and professional.",
      name: "Neha Chavan HR, Magic Software",
      image: "/assets/images/user-1.jpg",
    },
    {
      id: 5,
      icon: "path/to/icon-2.png",
      // heading: 'Testimonial Heading 2',
      text: "Thinkquotient Software has provided system administration and software development support for Circle Graphics for more than two years on a variety of projects in the Master Data Management and Web Development domains of practice Girde Graphics Ecommerce vertical. During these two years the Thinkquotient teams expertise in leva development, NEO4 and MySQL have been invaluable to Circle Graphics for completing projects and driving Ecommerce sales and customer retention marketing.",
      name: "Larz 1. Kegerreis, Senior Director Customer Data & Analytics Circle Graphics",
      image: "/assets/images/user-2.png",
    },
    // Add more testimonials data as needed
  ];

  const [activeTestimonial, setActiveTestimonial] = useState(0); // Track active testimonial slide index

  const testimonialsRef = useRef(null);

  const testimonialsSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (_, next) => setActiveTestimonial(next), // Update active testimonial on change
  };

  const goToTestimonialSlide = (index) => {
    testimonialsRef.current.slickGoTo(index);
  };

  return (
    <div>
      {/* Testimonials Section */}
      <div className="pb-20">
      <h1 className="text-3xl text-center font-bold mb-10">Testimonials</h1>
        <div className="center-icon " >
          <img
            src="/assets/images/double-quote.png"
            className="w-10 mx-auto pb-10"
            alt="Testimonial Icon"
          />
        </div>
        <Slider ref={testimonialsRef} {...testimonialsSettings}>
          {testimonialsData.map((testimonial, index) => (
            <div
              key={testimonial.id}
              className={`testimonial-slide ${
                activeTestimonial === index ? "active" : ""
              }`}
            >
              {/* Testimonial Content */}
              <div className="testimonial-content">
                <h1 className="text-xl mb-5 text-center">
                  {testimonial.heading}
                </h1>
                <p className="text-gray-500 testimonial-text">
                  {testimonial.text}
                </p>
                <p className="testimonial-name pt-5 px-4">{testimonial.name}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TestimonialsandBlogSec;
