import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    // List of paths where you want to skip the scroll-to-top behavior
    const pathsToSkipScrollTop = ['/careers/work-with-us'];

    // Check if the current pathname is in the list of paths to skip
    if (!pathsToSkipScrollTop.includes(location.pathname)) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return null;
};

export default ScrollToTop;
