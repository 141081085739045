import { Helmet } from "react-helmet";
import Footer from "../Components/Layout/Footer";
import Header from "../Components/Layout/Header";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import getHelmetContent from "../Components/Common/HelmetData";
import KnimeVideos from "./knimeVideos";
import { FaArrowCircleDown, FaBookReader } from "react-icons/fa";



const DownloadPDF = ({ pdfUrl, fileName }) => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = fileName; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return <FaArrowCircleDown size={'20px'}  marginLeft= {'10px'} onClick={handleDownload}  />


};
const KnimeNew = () => {
  useEffect(() => {
    document.title = "Knime - Think Quotient";
    Aos.init();
  }, []);
  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();
  const [selectedButtonmobile, setSelectedButtonmobile] = useState({
    label: "Button 1", info: <div className="m-4 md:flex  service-boxes">
      <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center"

      >
        <h1 className=" text-center text-2xl font-semibold mb-4">Data Integration</h1>
        <p className="text-md"><strong>Challenge</strong></p>
        <p className="mt-4 md:w-[100%]">Combining data from multiple sources with different formats and structures.</p>
        <p className="text-md mt-4"><strong>Solution With KNIME</strong></p>
        <p className="mt-4 md:w-[100%]">
          KNIME's extensive integration capabilities allow users to seamlessly connect to various data sources, perform data transformations,
          and merge disparate datasets into unified analytical workflows.
        </p>
      </div>
      <div className="md:w-1/2"


      >
        <img
          src="/assets/images/integration.jpg"
          className="object-cover h-full w-full"
        />
      </div>
    </div>
  })
  const [selectedButton, setSelectedButton] = useState({
    label: "Button 1", info:
      <p>{
        <div className="m-4 md:flex  service-boxes">
          <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <h1 className="text-center text-2xl font-semibold mb-4">Data Integration</h1>
            <p className="text-md mt-4"><strong>Challenge</strong></p>
            <p className="mt-4 md:w-[100%]">Combining data from multiple sources with different formats and structures.</p>
            <p className="text-md mt-4"><strong>Solution With KNIME </strong></p>
            <p className="mt-4 md:w-[100%]">
              KNIME's extensive integration capabilities allow users to seamlessly connect to various data sources, perform data transformations,
              and merge disparate datasets into unified analytical workflows.
            </p>
          </div>
          <div className="md:w-1/2"
            data-aos="fade-left"
            data-aos-duration="1000">
            <img
              src="/assets/images/integration.jpg"
              className="object-cover h-full w-full"
            />
          </div>
        </div>}</p>
  });



  const handleButtonClick = (buttonLabel, buttonInfo) => {
    setSelectedButton({ label: buttonLabel, info: buttonInfo });
  };

  const handleButtonClickmobile = (buttonLabel, buttonInfo) => {
    setSelectedButtonmobile({ label: buttonLabel, info: buttonInfo });
  };




  const [selectedButtonOne, setSelectedButtonOne] = useState({
    label: "Button 1", info:
      <p>{<div className="m-4 md:flex  service-boxes">
        <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <h1 className=" text-center text-2xl font-semibold mb-4 ">Employability</h1>
          <p className="mt-4 md:w-[100%]">
            Acquiring proficiency in KNIME enhances your employability in industries such as data science, data analytics, consulting, and research,
            where data-driven decision-making is crucial.
          </p>
        </div>
        <div className="md:w-1/2"
          data-aos="fade-left"
          data-aos-duration="1000"

        >
          <img
            src="/assets/images/employability.jpg"
            className="object-cover h-full w-full"
          />
        </div>
      </div>}</p>



  });

  const handleButtonClickOne = (buttonLabel, buttonInfo) => {
    setSelectedButtonOne({ label: buttonLabel, info: buttonInfo });
  };
  return (

    <>
      <Helmet>

        <meta name="title" content={metaTitle} />
        {/* Remaining meta tag */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* scipt for facebook */}
        {helmetContent.script}
        <script>
          {`
          (function (b, m, h, a, g) {
            b[a] = b[a] || [];
            b[a].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var k = m.getElementsByTagName(h)[0],
              e = m.createElement(h),
              c = a != "dataLayer" ? "&l=" + a : "";
            e.async = true;
            e.src = "https://www.googletagmanager.com/gtm.js?id=" + g + c;
            k.parentNode.insertBefore(e, k);
          })(window, document, "script", "dataLayer", "GTM-TN6B4BK");
          `
          }
        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>,

        <script>
          {
            `
      window.dataLayer = window.dataLayer || []; 
      function gtag() { 
        dataLayer.push(arguments) 
      } gtag("js", new Date()); gtag("config", "UA-143016865-1");
       `}
        </script>,

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>,

        <script>
          {`

        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments)}
        gtag('js', new Date());

        gtag('config', 'G-BSFPPN5E9D');
        `}

        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>,

        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments) }
      gtag('js', new Date());

      gtag('config', 'AW-574047969');
      `}

        </script>

      </Helmet>
      <div>
        {/* Header Section */}
        <Header />

        {/* Banner Section */}
        <div>
          <div className="knime-banner-sec flex items-center">
            <div className="container text-left text-white mx-auto">
              <div
                className="md:w-1/2 mx-4 md:mx-0"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h1 className="text-2xl md:text-4xl font-semibold mb-4 ">
                  Unlocking Data's Potential
                </h1>
                <p className="text-lg mb-8">
                  Transform Your Insights with KNIME Technology
                </p>
                <p className="text-lg mb-8">
                  KNIME is an open-source platform for creating data analytics workflows visually, enabling users to efficiently process,
                  analyze, and visualize data without the need for coding expertise.
                </p>
                <Link to={'/contact?fromKNIME=true'}>
                  <button className="btn-style-2 px-6 py-2 ">
                    Get In Touch With Us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-x-hidden">
          <div className="container mx-auto md:py-8 py-4 px-2 overflow-x-hidden">
            <h1 className="text-4xl font-bold mb-4 ">Why KNIME ?</h1>

            {/* For web view */}
            <div className="webonly">
              <div className="flex justify-around">
                <button onClick={() => handleButtonClick("Button 1",

                  <p>{<div className="m-4 md:flex  service-boxes">
                    <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      <h1 className=" text-center text-2xl font-semibold mb-4">Data Integration</h1>
                      <p className="text-md"><strong>Challenge</strong></p>
                      <p className="mt-4 md:w-[100%]">Combining data from multiple sources with different formats and structures.</p>
                      <p className="text-md mt-4"><strong>Solution With KNIME</strong></p>
                      <p className="mt-4 md:w-[100%]">
                        KNIME's extensive integration capabilities allow users to seamlessly connect to various data sources, perform data transformations,
                        and merge disparate datasets into unified analytical workflows.
                      </p>
                    </div>
                    <div className="md:w-1/2"
                      data-aos="fade-left"
                      data-aos-duration="1000"

                    >
                      <img
                        src="/assets/images/integration.jpg"
                        className="object-cover h-full w-full"
                      />
                    </div>
                  </div>}</p>

                )} className={` ${selectedButton.label === "Button 1" ? 'text-orange-600 underline underline-offset-8' : ''}`}>Data Integration</button>
                <button onClick={() => handleButtonClick("Button 2",

                  <p>{<div className="m-4 md:flex  service-boxes">
                    <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      <h1 className=" text-center text-2xl font-semibold mb-4 ">Data Processing</h1>
                      <p className="text-md"><strong>Challenge</strong></p>
                      <p className="mt-4 md:w-[100%]">Cleaning, filtering, and preparing raw data for analysis</p>
                      <p className="text-md mt-4"><strong>Solution With KNIME </strong></p>
                      <p className="mt-4 md:w-[100%]">
                        KNIME provides a wide range of processing nodes for tasks such as missing data imputation, outlier detection,
                        normalization, and feature engineering, streamlining the data preparation process.
                      </p>
                    </div>
                    <div className="md:w-1/2"
                      data-aos="fade-left"
                      data-aos-duration="1000"

                    >
                      <img
                        src="/assets/images/processing.jpg"
                        className="object-cover h-full w-full"
                      />
                    </div>
                  </div>}</p>
                )} style={{ marginRight: '15px' }} className={` ${selectedButton.label === "Button 2" ? 'text-orange-600 underline underline-offset-8' : ''}`}>Data Processing</button>
                <button onClick={() => handleButtonClick("Button 3",
                  <p>{<div className="m-4 md:flex  service-boxes">
                    <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      <h1 className=" text-center text-2xl font-semibold mb-4 ">Exploratory Data Analysis</h1>
                      <p className="text-md"><strong>Challenge</strong></p>
                      <p className="mt-4 md:w-[100%]">
                        Exploring and understanding complex datasets to identify patterns, trends, and outliers</p>
                      <p className="text-md mt-4"><strong>Solution With KNIME</strong></p>
                      <p className="mt-4 md:w-[100%]">
                        KNIME's visualization nodes enable users to create interactive visualizations, histograms, scatter plots, and heatmaps,
                        facilitating in-depth exploratory data analysis and insights generation.
                      </p>
                    </div>
                    <div className="md:w-1/2"
                      data-aos="fade-left"
                      data-aos-duration="1000"

                    >
                      <img
                        src="/assets/images/analysis.jpg"
                        className="object-cover h-full w-full"
                      />
                    </div>
                  </div>}</p>

                )} style={{ marginRight: '15px' }} className={` ${selectedButton.label === "Button 3" ? 'text-orange-600 underline underline-offset-8' : ''}`}>Exploratory Data Analysis</button>

                <button onClick={() => handleButtonClick("Button 4",
                  <p>{<div className="m-4 md:flex  service-boxes">
                    <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      <h1 className=" text-center text-2xl font-semibold mb-4 ">Machine Learning Model Development</h1>
                      <p className="text-md"><strong> Challenge</strong></p>
                      <p className="mt-4 md:w-[100%]">
                        Building, training, and evaluating predictive models on large datasets</p>
                      <p className="text-md mt-4"><strong>Solution With KNIME </strong></p>
                      <p className="mt-4 md:w-[100%]">
                        KNIME provides a suite of machine learning nodes for model building, evaluation, and optimization.
                        Users can experiment with different algorithms, parameter settings, and feature selection techniques
                        to develop robust predictive models.
                      </p>
                    </div>
                    <div className="md:w-1/2"
                      data-aos="fade-left"
                      data-aos-duration="1000"

                    >
                      <img
                        src="/assets/images/machineLearning.jpg"
                        className="object-cover h-full w-full"
                      />
                    </div>
                  </div>}</p>)} style={{ marginRight: '15px' }} className={` ${selectedButton.label === "Button 4" ? 'text-orange-600 underline underline-offset-8' : ''}`}>Machine Learning Model Development</button>
                <button onClick={() => handleButtonClick("Button 5",
                  <p>{<div className="m-4 md:flex  service-boxes">
                    <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      <h1 className=" text-center text-2xl font-semibold mb-4 ">Scalability and Performance</h1>
                      <p className="text-md"><strong>Challenge</strong></p>
                      <p className="mt-4 md:w-[100%]">
                        Processing and analyzing large volumes of data efficiently.</p>
                      <p className="text-md mt-4"><strong>Solution With KNIME </strong></p>
                      <p className="mt-4 md:w-[100%]">
                        KNIME supports parallel processing and distributed computing, enabling users to scale their
                        analytics workflows to handle big data scenarios effectively while maintaining performance and reliability.
                      </p>
                    </div>
                    <div className="md:w-1/2"
                      data-aos="fade-left"
                      data-aos-duration="1000"
                    >
                      <img
                        src="/assets/images/scalabilityAndPerformance.jpg"
                        className="object-cover h-full w-full"
                      />
                    </div>
                  </div>}</p>)} style={{ marginRight: '15px' }} className={` ${selectedButton.label === "Button 5" ? 'text-orange-600 underline underline-offset-8' : ''}`}>Scalability and Performance</button>
              </div>
              <div
              >           {selectedButton.info}
              </div>
            </div>

            {/* For mobile view */}
            <div className="mobileonly">
              {/* <h1>dfhjkljhg</h1> */}
              <div className="flex ju items-start flex-col justify-between md:justify-center">
                <button

                  onClick={() =>
                    handleButtonClickmobile(
                      "Button 1",
                      <p>{<div className="m-4 md:flex  service-boxes">
                        <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center"

                        >
                          <h1 className=" text-center text-2xl font-semibold mb-4">Data Integration</h1>
                          <p className="text-md"><strong>Challenge</strong></p>
                          <p className="mt-4 md:w-[100%]">Combining data from multiple sources with different formats and structures.</p>
                          <p className="text-md mt-4"><strong>Solution With KNIME</strong></p>
                          <p className="mt-4 md:w-[100%]">
                            KNIME's extensive integration capabilities allow users to seamlessly connect to various data sources, perform data transformations,
                            and merge disparate datasets into unified analytical workflows.
                          </p>
                        </div>
                        <div className="md:w-1/2"


                        >
                          <img
                            src="/assets/images/integration.jpg"
                            className="object-cover h-full w-full"
                          />
                        </div>
                      </div>}</p>

                    )
                  }
                  className={` ${selectedButtonmobile.label === "Button 1"
                    ? "text-orange-600 border-4 border-r-transparent border-t-transparent  p-1  border-b-transparent border-l-orange-600 "
                    : " p-1 "
                    }`}
                >
                  Data Integration
                </button>
                <button
                  onClick={() =>
                    handleButtonClickmobile(
                      "Button 2",
                      <p>{<div className="m-4 md:flex  service-boxes">
                        <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center"

                        >
                          <h1 className=" text-center text-2xl font-semibold mb-4 ">Data Processing</h1>
                          <p className="text-md"><strong>Challenge</strong></p>
                          <p className="mt-4 md:w-[100%]">Cleaning, filtering, and preparing raw data for analysis</p>
                          <p className="text-md mt-4"><strong>Solution With KNIME </strong></p>
                          <p className="mt-4 md:w-[100%]">
                            KNIME provides a wide range of processing nodes for tasks such as missing data imputation, outlier detection,
                            normalization, and feature engineering, streamlining the data preparation process.
                          </p>
                        </div>
                        <div className="md:w-1/2"


                        >
                          <img
                            src="/assets/images/processing.jpg"
                            className="object-cover h-full w-full"
                          />
                        </div>
                      </div>}</p>
                    )
                  }
                  className={`${selectedButtonmobile.label === "Button 2"
                    ? "text-orange-600 border-4 border-r-transparent border-t-transparent  p-1  border-b-transparent border-l-orange-600 "
                    : " p-1 "
                    }`}
                >
                  Data Processing
                </button>
                <button
                  onClick={() =>
                    handleButtonClickmobile(
                      "Button 3",
                      <p>{<div className="m-4 md:flex  service-boxes">
                        <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center"

                        >
                          <h1 className=" text-center text-2xl font-semibold mb-4 ">Exploratory Data Analysis</h1>
                          <p className="text-md"><strong>Challenge</strong></p>
                          <p className="mt-4 md:w-[100%]">
                            Exploring and understanding complex datasets to identify patterns, trends, and outliers</p>
                          <p className="text-md mt-4"><strong>Solution With KNIME</strong></p>
                          <p className="mt-4 md:w-[100%]">
                            KNIME's visualization nodes enable users to create interactive visualizations, histograms, scatter plots, and heatmaps,
                            facilitating in-depth exploratory data analysis and insights generation.
                          </p>
                        </div>
                        <div className="md:w-1/2"


                        >
                          <img
                            src="/assets/images/analysis.jpg"
                            className="object-cover h-full w-full"
                          />
                        </div>
                      </div>}</p>
                    )
                  }
                  className={`${selectedButtonmobile.label === "Button 3"
                    ? "text-orange-600 border-4 border-r-transparent border-t-transparent  p-1  border-b-transparent border-l-orange-600"
                    : " p-1 "
                    }`}
                >
                  Exploratory Data Analysis
                </button>
                <button
                  onClick={() =>
                    handleButtonClickmobile(
                      "Button 4",
                      <p>{<div className="m-4 md:flex  service-boxes">
                        <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center"

                        >
                          <h1 className=" text-center text-2xl font-semibold mb-4 ">Machine Learning Model Development</h1>
                          <p className="text-md"><strong> Challenge</strong></p>
                          <p className="mt-4 md:w-[100%]">
                            Building, training, and evaluating predictive models on large datasets</p>
                          <p className="text-md mt-4"><strong>Solution With KNIME </strong></p>
                          <p className="mt-4 md:w-[100%]">
                            KNIME provides a suite of machine learning nodes for model building, evaluation, and optimization.
                            Users can experiment with different algorithms, parameter settings, and feature selection techniques
                            to develop robust predictive models.
                          </p>
                        </div>
                        <div className="md:w-1/2"


                        >
                          <img
                            src="/assets/images/machineLearning.jpg"
                            className="object-cover h-full w-full"
                          />
                        </div>
                      </div>}</p>
                    )
                  }
                  className={`${selectedButtonmobile.label === "Button 4"
                    ? "text-orange-600 border-4 border-r-transparent border-t-transparent  p-1  border-b-transparent border-l-orange-600"
                    : " p-1 "
                    }`}
                >
                  Machine Learning Model Development
                </button>
                <button
                  onClick={() =>
                    handleButtonClickmobile(
                      "Button 5",
                      <p>{<div className="m-4 md:flex  service-boxes">
                        <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center"

                        >
                          <h1 className=" text-center text-2xl font-semibold mb-4 ">Scalability and Performance</h1>
                          <p className="text-md"><strong>Challenge</strong></p>
                          <p className="mt-4 md:w-[100%]">
                            Processing and analyzing large volumes of data efficiently.</p>
                          <p className="text-md mt-4"><strong>Solution With KNIME </strong></p>
                          <p className="mt-4 md:w-[100%]">
                            KNIME supports parallel processing and distributed computing, enabling users to scale their
                            analytics workflows to handle big data scenarios effectively while maintaining performance and reliability.
                          </p>
                        </div>
                        <div className="md:w-1/2"

                        >
                          <img
                            src="/assets/images/scalabilityAndPerformance.jpg"
                            className="object-cover h-full w-full"
                          />
                        </div>
                      </div>}</p>
                    )
                  }
                  className={`${selectedButtonmobile.label === "Button 5"
                    ? "text-orange-600 border-4 border-r-transparent border-t-transparent  p-1  border-b-transparent border-l-orange-600"
                    : " p-1 "
                    }`}
                >
                  Scalability and Performance
                </button>
              </div>
              <div>{selectedButtonmobile.info}</div>
            </div>

          </div>
        </div>

        <div className="overflow-x-hidden">
          <div className="container mx-auto md:py-8 py-4 px-2">
            <h1 className="text-4xl font-bold mb-6">Future With KNIME</h1>

            {/* For web view */}
            <div className="webonly">
              <div className="flex justify-around text-xl">
                <button onClick={() => handleButtonClickOne("Button 1",

                  <p>{<div className="m-4 md:flex  service-boxes">
                    <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      <h1 className="text-center  text-2xl font-semibold mb-4 ">Employability</h1>
                      <p className="mt-4 md:w-[100%]">
                        Acquiring proficiency in KNIME enhances your employability in industries such as data science, data analytics, consulting, and research,
                        where data-driven decision-making is crucial.
                      </p>
                    </div>
                    <div className="md:w-1/2"
                      data-aos="fade-left"
                      data-aos-duration="1000"

                    >
                      <img
                        src="/assets/images/employability.jpg"
                        className="object-cover h-full w-full"
                      />
                    </div>
                  </div>}</p>

                )} className={`${selectedButtonOne.label === "Button 1" ? 'text-orange-600 underline underline-offset-8' : ''}`}>Employability</button>
                <button onClick={() => handleButtonClickOne("Button 3",
                  <p>{<div className="m-4 md:flex  service-boxes">
                    <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      <h1 className=" text-center text-2xl font-semibold mb-4 ">Career Advancement</h1>
                      <p className="mt-4 md:w-[100%]">
                        KNIME proficiency can facilitate career advancement opportunities, enabling you to transition into more senior positions with responsibilities in data strategy,
                        analytics leadership, or consulting.
                      </p>
                    </div>
                    <div className="md:w-1/2"
                      data-aos="fade-left"
                      data-aos-duration="1000"

                    >
                      <img
                        src="/assets/images/careerAdvancement.jpg"
                        className="object-cover h-full w-full"
                      />
                    </div>
                  </div>}</p>

                )} style={{ marginRight: '15px' }} className={` ${selectedButtonOne.label === "Button 3" ? 'text-orange-600 underline underline-offset-8' : ''}`}>Career Advancement</button>

                <button onClick={() => handleButtonClickOne("Button 4",
                  <p>{<div className="m-4 md:flex  service-boxes">
                    <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      <h1 className=" text-center text-2xl font-semibold mb-4 ">Continuous Learning</h1>
                      <p className="mt-4 md:w-[100%]">
                        KNIME's extensive ecosystem and community support provide opportunities for continuous learning and skill development, allowing you to stay updated with emerging trends
                        and technologies in the field of data analytics
                      </p>
                    </div>
                    <div className="md:w-1/2"
                      data-aos="fade-left"
                      data-aos-duration="1000"

                    >
                      <img
                        src="/assets/images/continuousLearning.jpg"
                        className="object-cover h-full w-full"
                      />
                    </div>
                  </div>}</p>)} style={{ marginRight: '15px' }} className={` ${selectedButtonOne.label === "Button 4" ? 'text-orange-600 underline underline-offset-8' : ''}`}>Continuous Learning</button>
                <button onClick={() => handleButtonClickOne("Button 5",
                  <p>{
                    <div className="m-4 md:flex  service-boxes">
                      <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <h1 className=" text-center text-2xl font-semibold mb-4 ">Professional Networking</h1>
                        <p className="mt-4 md:w-[100%]">
                          Engaging with the KNIME community, attending workshops, and participating in industry events can expand your professional network,
                          connecting you with potential employers, mentors, and collaborators.
                        </p>
                      </div>
                      <div className="md:w-1/2"
                        data-aos="fade-left"
                        data-aos-duration="1000"

                      >
                        <img
                          src="/assets/images/professionalNetworking.jpg"
                          className="object-cover h-full w-full"
                        />
                      </div>
                    </div>
                  }</p>)} style={{ marginRight: '15px' }} className={`${selectedButtonOne.label === "Button 5" ? 'text-orange-600 underline underline-offset-8' : ''}`}>Professional Networking</button>
              </div>

              <div
              >           {selectedButtonOne.info}
              </div>
            </div>

            {/* For mobile view */}
<div className="mobileonly">
  <div className="flex ju items-start flex-col justify-between md:justify-center">
    <button
      onClick={() => handleButtonClickOne(
        "Button 1",
        <p>{<div className="m-4 md:flex service-boxes">
          <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center" data-aos="fade-right" data-aos-duration="1000">
            <h1 className="text-center text-2xl font-semibold mb-4">Employability</h1>
            <p className="mt-4 md:w-[100%]">
              Acquiring proficiency in KNIME enhances your employability in industries such as data science, data analytics, consulting, and research,
              where data-driven decision-making is crucial.
            </p>
          </div>
          <div className="md:w-1/2" data-aos="fade-left" data-aos-duration="1000">
            <img
              src="/assets/images/employability.jpg"
              className="object-cover h-full w-full"
            />
          </div>
        </div>}</p>
      )}
      className={`${selectedButtonOne.label === "Button 1" ? "text-orange-600 border-4 border-r-transparent border-t-transparent p-1 border-b-transparent border-l-orange-600" : " p-1"}`}
    >
      Employability
    </button>
    <button
      onClick={() => handleButtonClickOne(
        "Button 3",
        <p>{<div className="m-4 md:flex service-boxes">
          <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center" data-aos="fade-right" data-aos-duration="1000">
            <h1 className="text-center text-2xl font-semibold mb-4">Career Advancement</h1>
            <p className="mt-4 md:w-[100%]">
              KNIME proficiency can facilitate career advancement opportunities, enabling you to transition into more senior positions with responsibilities in data strategy,
              analytics leadership, or consulting.
            </p>
          </div>
          <div className="md:w-1/2" data-aos="fade-left" data-aos-duration="1000">
            <img
              src="/assets/images/careerAdvancement.jpg"
              className="object-cover h-full w-full"
            />
          </div>
        </div>}</p>
      )}
      className={`${selectedButtonOne.label === "Button 3" ? "text-orange-600 border-4 border-r-transparent border-t-transparent p-1 border-b-transparent border-l-orange-600" : " p-1"}`}
    >
      Career Advancement
    </button>
    <button
      onClick={() => handleButtonClickOne(
        "Button 4",
        <p>{<div className="m-4 md:flex service-boxes">
          <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center" data-aos="fade-right" data-aos-duration="1000">
            <h1 className="text-center text-2xl font-semibold mb-4">Continuous Learning</h1>
            <p className="mt-4 md:w-[100%]">
              KNIME's extensive ecosystem and community support provide opportunities for continuous learning and skill development, allowing you to stay updated with emerging trends
              and technologies in the field of data analytics.
            </p>
          </div>
          <div className="md:w-1/2" data-aos="fade-left" data-aos-duration="1000">
            <img
              src="/assets/images/continuousLearning.jpg"
              className="object-cover h-full w-full"
            />
          </div>
        </div>}</p>
      )}
      className={`${selectedButtonOne.label === "Button 4" ? "text-orange-600 border-4 border-r-transparent border-t-transparent p-1 border-b-transparent border-l-orange-600" : " p-1"}`}
    >
      Continuous Learning
    </button>
    <button
      onClick={() => handleButtonClickOne(
        "Button 5",
        <p>{<div className="m-4 md:flex service-boxes">
          <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center" data-aos="fade-right" data-aos-duration="1000">
            <h1 className="text-center text-2xl font-semibold mb-4">Professional Networking</h1>
            <p className="mt-4 md:w-[100%]">
              Engaging with the KNIME community, attending workshops, and participating in industry events can expand your professional network,
              connecting you with potential employers, mentors, and collaborators.
            </p>
          </div>
          <div className="md:w-1/2" data-aos="fade-left" data-aos-duration="1000">
            <img
              src="/assets/images/professionalNetworking.jpg"
              className="object-cover h-full w-full"
            />
          </div>
        </div>}</p>
      )}
      className={`${selectedButtonOne.label === "Button 5" ? "text-orange-600 border-4 border-r-transparent border-t-transparent p-1 border-b-transparent border-l-orange-600" : " p-1"}`}
    >
      Professional Networking
    </button>
  </div>
  <div>{selectedButtonOne.info}</div>
</div>




          </div>
        </div>

        <div className="container mx-auto md:py-8 py-4 px-2">
          <h1 className="text-4xl font-bold mb-4 ">Why Choose Us ?</h1>
          <div data-aos="fade-up"
            data-aos-duration="1000">
            Choose us for KNIME training and unlock your data analytics potential with expert guidance tailored to your learning needs,
            ensuring you gain practical skills for success in the digital age.
          </div>

          {/* Image for web view */}
          <div className="hidden md:block" style={{ width: '60%', height: '40%', marginLeft: '20%', marginTop: '3%' }} data-aos="fade-up" data-aos-duration="1000">
            <img src="/assets/images/KNIME Creative.png" alt="KNIME Creative" />
          </div>

          {/* Image for mobile view */}
          <div className="block md:hidden">
            <img
              src="/assets/images/KNIME Creative.png"
              className="object-cover h-full w-full"
              alt="KNIME Creative"
            />
          </div>

        </div>


        <div>
          <div className="container mx-auto md:py-8 py-4 px-2">
            <h1 className="text-2xl font-bold mb-4">Case Study : Unlocking Data Potential: A Strategic Guide to KNIME Analytics
              Mastery for Business Growth and Development</h1>
            <div className="flex flex-col py-14 bg-gray-100 fade-in-down px-8">
              <h1 className="text-md font-normal container mx-auto"
                data-aos="fade-up"
                data-aos-duration="1000">
                <div>
                  Discover how ThinkQuotient Organization harnesses the power of KNIME to revolutionize training and placement strategies. Explore our case study showcasing the seamless integration of KNIME for in-depth analysis of Marketing and Placement departments, driving informed decision-making and
                  ensuring excellence in talent development and industry alignment...
                  <a href="assets/whitePaper/KNIME_Case_Study_2.pdf" target="_blank"><span style={{ color: 'darkBlue', textDecoration: 'underline' }}>Read More</span>
                  </a>



                </div> </h1>
            </div>
          </div>
        </div>
        <div className="container mx-auto md:py-8 px-2">
          <Link to={'/contact?fromKNIME=true'}>
            <button className="btn-style-3 px-6 py-2">For Further Inquires</button>
          </Link>
        </div>
        <div>
          <div className="container mx-auto md:py-4 py-4 py-4 px-2">
            <h1 className="text-2xl font-bold mb-4">Unlock the Power of Data Analysis with KNIME </h1> </div>
        </div>
        <KnimeVideos />
        <div>

          <div className="container mx-auto md:py-10 py-4 px-2">
            <h1 className=" text-center text-4xl font-bold mb-4">White Papers</h1>
            <div className="flex flex-col py-10 bg-gray-100 fade-in-down px-4"
              style={{ height: '300px', width: '100%', overflowY: 'scroll' }}>
              <h1 data-aos="fade-up" data-aos-duration="1000">
                <div>

                  <div className="flex md:ms-16 ms-4 ps-6 text-md  list-items">
                    Empowering Efficiency: A Comprehensive Guide to Optimizing &nbsp;<a href="assets/whitePaper/KNIME_Case_Study_1.pdf" target="_blank"><FaBookReader size={18} />
                    </a> &nbsp;<DownloadPDF pdfUrl="assets/whitePaper/KNIME_Case_Study_1.pdf" fileName="KNIME_Case_Study_1" />
                  </div>
                  <br /><div className="flex md:ms-16 ms-4 ps-6 text-md  list-items">
                    Unlocking Data Potential: A Strategic Guide to KNIME Analytics Mastery for Business Growth and Development &nbsp;<a href="assets/whitePaper/KNIME_Case_Study_2.pdf" target="_blank"><FaBookReader size={18} />
                    </a> &nbsp;<DownloadPDF pdfUrl="assets/whitePaper/KNIME_Case_Study_2.pdf" fileName="KNIME_Case_Study_2" />
                  </div> <br />
                  <div className="flex md:ms-16 ms-4 ps-6 text-md   list-items " >
                    Maximizing Data Potential: A Strategic Guide to KNIME Analytics Mastery &nbsp;<a href="assets/whitePaper/KNIME_White_Paper_3.pdf" target="_blank"><FaBookReader size={18} />
                    </a> &nbsp;<DownloadPDF pdfUrl="assets/whitePaper/KNIME_White_Paper_3.pdf" fileName="KNIME_Case_Study_3" />
                  </div> <br />
                  <div className="flex md:ms-16 ms-4 ps-6 text-md   list-items ">
                    Enhancing Admission Predictions for ThinkQuotient Organization with KNIME &nbsp;<a href="assets/whitePaper/KNIME_Case_Study_4.pdf" target="_blank"><FaBookReader size={18} />
                    </a> &nbsp;<DownloadPDF pdfUrl="assets/whitePaper/KNIME_Case_Study_4.pdf" fileName="KNIME_Case_Study_4" />
                  </div> <br />
                  <div className="flex md:ms-16 ms-4 ps-6 text-md  list-items">
                    Best Practices for Collaborative Data Science with KNIME &nbsp;<a href="assets/whitePaper/KNIME_White_Paper_5.pdf" target="_blank"><FaBookReader size={18} />
                    </a> &nbsp;<DownloadPDF pdfUrl="assets/whitePaper/KNIME_White_Paper_5.pdf" fileName="KNIME_Case_Study_5" />
                  </div> <br />
                  <div className="flex md:ms-16 ms-4 ps-6 text-md   list-items">
                    KNIME Analytics Platform: Unlocking the Power of Efficient Database Interactions &nbsp;<a href="assets/whitePaper/KNIME_White_Paper_6.pdf" target="_blank"><FaBookReader size={18} />
                    </a> &nbsp;<DownloadPDF pdfUrl="assets/whitePaper/KNIME_White_Paper_6.pdf" fileName="KNIME_Case_Study_6" />
                  </div> <br />
                  <div className="flex md:ms-16 ms-4 ps-6 text-md    list-items ">
                    Mastering Data Flow Dynamics: Exploring the Power of Flow Variable KNIME &nbsp;<a href="assets/whitePaper/KNIME_White_Paper_7.pdf" target="_blank"><FaBookReader size={18} />
                    </a> &nbsp;<DownloadPDF pdfUrl="assets/whitePaper/KNIME_White_Paper_7.pdf" fileName="KNIME_Case_Study_7" />
                  </div> <br />
                  <div className="flex md:ms-16 ms-4 ps-6 text-md   list-items ">
                    Optimizing Workflow Invocation in KNIME: Strategies for Enhanced Data Productivity &nbsp;
                    <a href="assets/whitePaper/KNIME_White_Paper_8.pdf" target="_blank"><FaBookReader size={18} />
                    </a> &nbsp;<DownloadPDF pdfUrl="assets/whitePaper/KNIME_White_Paper_8.pdf" fileName="KNIME_Case_Study_8" />
                  </div>

                </div>

              </h1>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default KnimeNew;
