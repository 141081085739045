import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../../Components/Layout/Header";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import getHelmetContent from "../../Components/Common/HelmetData";


const HLT = () => {
  useEffect(() => {
    document.title = "HLT - Think Quotient";
    AOS.init();

  }, []);

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    // autoplay:true,
    slidesToScroll: 1,
    prevArrow: <div className="slick-arrow slick-prev ">&#x2190;</div>,
    nextArrow: <div className="slick-arrow slick-next">&#x2192;</div>,
    adaptiveHeight: true,
  };

  const clientLogos = [
    "/assets/images/logo-1.png",
    "/assets/images/logo-2.png",
    "/assets/images/logo-3.webp",
    "/assets/images/logo-4.svg",
    "/assets/images/logo-2.png",
    "/assets/images/logo-3.webp",
  ];

  const visionMissionContent = [
    {
      title: "HLT Tracker (Hot Lead Tracker) ",
      description:
        "The HLT Tracker module serves as a central hub for tracking leads generated by marketing teams. It offers a range of functionalities, including lead ownership management, status tracking, lead import/export, follow-up scheduling, backup capabilities, and duplicate lead detection.",
      list_title: "Key Technical Features:",
      list: [
        "Lead Ownership Management: Allows users to assign and reassign leads to different team members.",
        "Status Tracking: Enables the tracking of lead status, providing real-time insights into the progress of each lead.",
        "Lead Import/Export: Supports lead data import via Excel, streamlining the process of adding leads individually or in bulk.",
        "Follow-Up Scheduling: Provides the ability to schedule follow-up dates for leads, with automated highlighting when follow-up dates approach.",
        "Backup Capabilities: Allows for the secure backup of all lead data.",
        "Duplicate Lead Detection: Prevents multiple employees from working on the same lead by detecting duplicates.",
      ],
      image: "/assets/images/hltt.jpg",
    },
    {
      title: "Task Tracker ",
      description: 
        "The Task Tracker module is designed for efficient task management within the system. It includes features for assigning, tracking, prioritizing, and managing tasks, with options for setting deadlines and automated notifications.",
      list_title: "Key Technical Features:",
      list: [
        "Task Assignment: Users can assign tasks to specific team members, facilitating task distribution and workload management.",
        "Priority and Status: Tasks can be categorized with priority levels (P1 to P4) and status (Open, Pending, Fixed) for better organization.",
        "Deadline Management: Users can set end dates for tasks, and the system sends warning emails and text messages to users approaching deadlines.",
        "Task Editing: Allows users to modify task details as needed.",
        "Backup Capabilities: Offers the option to create backups of all tasks, ensuring data integrity.",
      ],
      image: "/assets/images/tt.jpg",
    },
    {
      title: "Recurring Task Tracker",
      description:
        "The Recurring Task Tracker module simplifies the management of recurring tasks, allowing users to create and schedule tasks that repeat on a daily, weekly, or custom basis. Daily alerts keep users informed about their recurring tasks.",
      list_title: "Key Technical Features:",
      list: [
        " Recurring Task Creation: Users can create tasks that repeat at specified intervals, such as daily, weekly, or on custom days.",
        " Daily Alerts: Automatically sends daily alerts to users with details of their recurring tasks.",
      ],
      image: "/assets/images/rtt.jpg",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    arrow: true,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
    prevArrow: (
      <button type="button" className="slick-arrow slick-prev">
        Left &lt;
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-arrow slick-next">
        Right &gt;
      </button>
    ),
  };
  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();

  return (
    
    <>
      <Helmet>
        
        <meta name="title" content={metaTitle} />
        {/* Remaining meta tag */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}
        
        {/* scipt for facebook */}
        {helmetContent.script}
        <script>
          {`
          (function (b, m, h, a, g) {
            b[a] = b[a] || [];
            b[a].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var k = m.getElementsByTagName(h)[0],
              e = m.createElement(h),
              c = a != "dataLayer" ? "&l=" + a : "";
            e.async = true;
            e.src = "https://www.googletagmanager.com/gtm.js?id=" + g + c;
            k.parentNode.insertBefore(e, k);
          })(window, document, "script", "dataLayer", "GTM-TN6B4BK");
          `
          }
        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>,

        <script>
          {
            `
      window.dataLayer = window.dataLayer || []; 
      function gtag() { 
        dataLayer.push(arguments) 
      } gtag("js", new Date()); gtag("config", "UA-143016865-1");
       `}
        </script>,

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>,

        <script>
          {`

        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments)}
        gtag('js', new Date());

        gtag('config', 'G-BSFPPN5E9D');
        `}

        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>,

        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments) }
      gtag('js', new Date());

      gtag('config', 'AW-574047969');
      `}

        </script>
        
      </Helmet>
    <div className="overflow-hidden">
      {/* Header Section */}
      <Header />

      {/* Banner Section */}
      <div className="flex">
        <div className="hlt-banner-sec flex items-center">
          <div className="container text-left text-white mx-auto">
            <div className="md:w-1/2 mx-4 md:mx-0"
            data-aos="fade-up"
            data-aos-duration="1000">
              <h1 className="text-2xl md:text-3xl font-semibold mb-4 ">
                The HLT Tracker System is a comprehensive software solution
              </h1>
              <Link to="/contact?scrollTo=contact-form">
                <button className="btn-style-2 px-6 py-2 ">
                  Get In Touch With Us
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center py-14  bg-gray-100 fade-in-down px-6">
        <h1 className="text-lg font-normal container mx-auto text-center"
        data-aos="fade-up"
        data-aos-duration="1000">
          <div>
            <p className="font-bold text-2xl ">
              The HLT Tracker System is a comprehensive software solution
            </p>
          </div>
          <br /> Designed to streamline lead management, task tracking, and
          recurring task management, developed  usingSpring Boot, JPA, and Angular technologies.
          This system provides a sophisticated and
          user-friendly platform for managing various aspects of business
          operations. This will provide an in-depth technical overview
          of the system, highlighting its key features and technical
          architecture.
        </h1>
      </div>

      

      {/* Vision & Mission Slider */}
      <div className="container mx-auto m-8 about-vm-section pb-20">
        <Slider {...sliderSettings} className="relative pt-12">
          {visionMissionContent.map((section, sectionIndex) => (
            <div key={sectionIndex} className="">
              <h1 className="md:text-2xl font-bold md:w-1/4 underline flex items-start ">
                <svg
                  className="md:w-8 w-4  mr-6"
                  viewBox="-5 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  sketch="http://www.bohemiancoding.com/sketch/ns"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <title>chevron-right</title>
                    <desc>Created with Sketch Beta.</desc> <defs> </defs>
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                      type="MSPage"
                    >
                      <g
                        id="Icon-Set"
                        type="MSLayerGroup"
                        transform="translate(-473.000000, -1195.000000)"
                        fill="#000000"
                      >
                        <path
                          d="M486.717,1206.22 L474.71,1195.28 C474.316,1194.89 473.678,1194.89 473.283,1195.28 C472.89,1195.67 472.89,1196.31 473.283,1196.7 L484.566,1206.98 L473.283,1217.27 C472.89,1217.66 472.89,1218.29 473.283,1218.69 C473.678,1219.08 474.316,1219.08 474.71,1218.69 L486.717,1207.75 C486.927,1207.54 487.017,1207.26 487.003,1206.98 C487.017,1206.71 486.927,1206.43 486.717,1206.22"
                          id="chevron-right"
                          type="MSShapeGroup"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
                {section.title}
              </h1>
              <div className="md:flex  justify-center h-fit">
                <img
                  src={section.image}
                  alt={`Vision/Mission ${sectionIndex + 1}`}
                  className="pslider-box-img my-20 md:px-0 px-10 object-cover object-left"
                />
                <div className="pslider-box flex  flex-col justify-center  ">
                  <p className="md:ps-14 ps-4 font-semibold md:w-2/3 text-lg mb-6 p-4">
                    {section.description}
                  </p>
                  {section.list.map((item, index) => (
                    <p
                      key={index}
                      className="md:ms-16 ms-4 ps-6 text-md  w-2/3  list-items my-5"
                    >
                      {item}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div>
        <div className="container mx-auto pb-20">
          <div className="text-black text-3xl font-bold text-center">
            Technical Architecture
          </div>
        </div>
      </div>

      {/* text and image box */}

      {/* First */}
      <div className="m-0 md:flex  service-boxes">
        <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center"
        data-aos="fade-right"
        data-aos-duration="1000"
        >
          <h1 className="text-2xl font-bold">Technologies Used:</h1>
          <p className="mt-4 md:w-[80%] list-items ps-6 mb-4">
            Development Framework: SpringBoot Framework for robust and scalable application development.
          </p>
          <p className="mt-4 md:w-[80%] list-items ps-6 mb-4">
            Front-End Technologies: Angular and React for creating an
            intuitive and responsive user interface.
          </p>
          <p className="mt-4 md:w-[80%] list-items ps-6 mb-4">
            Database: Utilizes a relational database management system (RDBMS) such as MySQL for data storage.
          </p>
        </div>
        <div className="md:w-1/2" 
           data-aos="fade-left"
           data-aos-duration="1000"
        >
          <img
            src="/assets/images/tech-used.jpg"
            className="object-cover h-full w-full"
          />
        </div>
      </div>

      {/* Second */}
      <div className="m-0 md:flex service-boxes ">
        <div className="w-1/2 hidden md:block" 
           data-aos="fade-right"
           data-aos-duration="1000"
        >
          <img
            src="/assets/images/system-c.jpg"
            className="object-cover h-full w-full"
          />
        </div>
        <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center"
           data-aos="fade-left"
           data-aos-duration="1000">
          <h1 className="text-2xl font-bold">System Components:</h1>
          <p className="mt-4 md:w-[80%] list-items ps-6 mb-4">
            Front-End: The user interface is developed using HTML, CSS, and
            JavaScript to provide an interactive and user-friendly experience.
          </p>
          <p className="mt-4 md:w-[80%] list-items ps-6 mb-4">
            Back-End: The .NET framework is employed to create the server-side
            logic, handle database interactions, and manage the system's core
            functionalities.
          </p>
          <p className="mt-4 md:w-[80%] list-items ps-6 mb-4">
            Database: Microsoft SQL Server or a compatible RDBMS is used to
            store and manage data related to leads, tasks, users, and system
            configurations.
          </p>
          <p className="mt-4 md:w-[80%] list-items ps-6 mb-4">
            Data Integration: Excel import/export functionality is implemented
            for seamless data transfer.
          </p>
        </div>
        <div className="md:w-1/2 md:hidden block">
          <img
            src="/assets/images/system-c.jpg"
            className="object-cover h-full w-full"
          />
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
    </>
  );
};

export default HLT;
