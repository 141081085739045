import React, { useEffect, useRef } from "react";
import Header from "../../Components/Layout/Header";
import ClientLogoCarousel from "../../Components/Home/ClientLogoCarousel";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import getHelmetContent from "../../Components/Common/HelmetData";
import { Helmet } from "react-helmet";
import WhyUs from "../../Components/Home/WhyUs";

const ProductDevelopment = () => {
  const webDevelopmentRef = useRef(null);
  const softwareDevelopmentRef = useRef(null);
  const appDevelopmentRef = useRef(null);

  useEffect(() => {
    document.title = "Product Development - Think Quotient";
    AOS.init();

    // // Check if the URL has the anchor link, and scroll to the section if present
    // const hash = window.location.hash.substring(1);
    // if (hash === "web-development") {
    //   scrollToSection(webDevelopmentRef);
    // } else if (hash === "software-development") {
    //   scrollToSection(softwareDevelopmentRef);
    // } else if (hash === "app-development") {
    //   scrollToSection(appDevelopmentRef);
    // }
  }, []);


  useEffect(() => {
    // Get the entire hash string, including the '?'
    const hashString = window.location.hash;
  
    // Extract the path and query parameters
    const [path, queryString] = hashString.slice(2).split('?'); // Remove the leading '#/' and split at '?'
  
    // If you need to parse the query parameters, you can use URLSearchParams
    if (queryString) {
      const queryParams = new URLSearchParams(queryString);
      const scrollToSection = queryParams.get('scrollTo');
      console.log("hello "+scrollToSection);
      // Scroll to the specified section
      if (scrollToSection) {
        const targetSection = document.getElementById(scrollToSection);
  
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  
    // Rest of your useEffect logic
  }, []);
  
  

  useEffect(() => {
    // Get the entire hash string, including the '?'
    const hashString = window.location.hash;
  
    // Extract the path and query parameters
    const [path, queryString] = hashString.slice(2).split('?'); // Remove the leading '#/' and split at '?'
  
    // If you need to parse the query parameters, you can use URLSearchParams
    if (queryString) {
      const queryParams = new URLSearchParams(queryString);
      const scrollToSection = queryParams.get('scrollTo');
  
      // Scroll to the specified section
      if (scrollToSection) {
        const targetSection = document.getElementById(scrollToSection);
  
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  
    // Rest of your useEffect logic
  }, []);



  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  
  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();
  return (
    
    <>
      <Helmet>
        
        <meta name="title" content={metaTitle} />
        {/* Remaining meta tag */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}
        
        {/* scipt for facebook */}
        {helmetContent.script}
        <script>
          {`
          (function (b, m, h, a, g) {
            b[a] = b[a] || [];
            b[a].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var k = m.getElementsByTagName(h)[0],
              e = m.createElement(h),
              c = a != "dataLayer" ? "&l=" + a : "";
            e.async = true;
            e.src = "https://www.googletagmanager.com/gtm.js?id=" + g + c;
            k.parentNode.insertBefore(e, k);
          })(window, document, "script", "dataLayer", "GTM-TN6B4BK");
          `
          }
        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>,

        <script>
          {
            `
      window.dataLayer = window.dataLayer || []; 
      function gtag() { 
        dataLayer.push(arguments) 
      } gtag("js", new Date()); gtag("config", "UA-143016865-1");
       `}
        </script>,

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>,

        <script>
          {`

        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments)}
        gtag('js', new Date());

        gtag('config', 'G-BSFPPN5E9D');
        `}

        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>,

        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments) }
      gtag('js', new Date());

      gtag('config', 'AW-574047969');
      `}

        </script>
        
      </Helmet>
  
    <div className="overflow-hidden">
      {/* Header Section */}
      <Header />

      {/* Banner Section */}
      <div>
        <div className="service-banner-sec flex items-center">
          <div className="container text-left text-white mx-auto">
            <div
              className="md:w-1/2 mx-4 md:mx-0"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h1 className="text-2xl md:text-4xl font-semibold mb-4">
                Turn your ideas into market-ready products
              </h1>
              <p className="md:text-xl mb-8">
                with our comprehensive product development services. From
                concept to launch, we'll guide you through the entire process.
              </p>
              <Link to="/contact?scrollTo=contact-form">
                <button className="btn-style-2 px-6 py-2 ">
                  Get In Touch With Us
                </button>
              </Link>
              
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="container mx-auto md:py-20 py-10 px-2">
          <div className="text-black text-2xl md:text-5xl font-bold text-center md:w-1/2 mx-auto"
          data-aos="fade-up"
          data-aos-duration="1000"
          >
            Let ThinkQuotient Help You Create Your
            <span
              className="about-us-banner-txt"
              
              ref={webDevelopmentRef}
            >
              {" "}
              Online Presence
            </span>
          </div>
        </div>
      </div>

      {/* text and image box */}

      {/* First */}
      <div className="m-0 md:flex  service-boxes">
        <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center"
        data-aos="fade-right"
        data-aos-duration="1000"
        >
          <h1 className="text-2xl font-bold">Web Development</h1>
          <p className="mt-4 md:w-[80%]"  >
            We harness top web frameworks to create impactful websites that
            strengthen your online presence. From startups to established
            enterprises, we craft seamless digital experiences.
          </p>
        </div>
        <div className="md:w-1/2" 
          data-aos="fade-left"
          data-aos-duration="1000"
          id="web-development"
        >
          <img
            src="/assets/images/web-development.jpg"
            className="object-cover h-full w-full"
          />
        </div>
      </div>

      {/* Second */}
      <div
        className="m-0 md:flex service-boxes "
        id="software-development"
        // ref={softwareDevelopmentRef}
      >
        <div className="w-1/2 hidden md:block"   data-aos="fade-right"
        data-aos-duration="1000">
          <img
            src="/assets/images/software-dev.jpg"
            className="object-cover h-full w-full "
          />
        </div>
        <div className="md:w-1/2 bg-gray-200 md:p-20 p-10 flex flex-col justify-center" 
         data-aos="fade-left"
         data-aos-duration="1000"
        >
          <h1 className="text-2xl font-bold">Software Development</h1>
          <p className="mt-4 md:w-[80%]">
            Your partner for end-to-end software development. Elevate
            productivity and lead your industry with our tailored solutions that
            deliver innovation and efficiency.
          </p>
        </div>
        <div className=" md:hidden block">
          <img
            src="/assets/images/software-dev.jpg"
            className="object-cover h-full w-full "
          />
        </div>
      </div>

      {/* Third */}

      <div
        className="m-0 md:flex service-boxes"
        id="app-development"
        ref={appDevelopmentRef}
      >
        <div className="md:w-1/2 bg-gray-200 p-10 md:p-20 flex flex-col justify-center"   data-aos="fade-right"
        data-aos-duration="1000" >
          <h1 className="text-2xl font-bold">App Development</h1>
          <p className="mt-4 md:w-[80%]">
            Transforming visions into mobile reality. We specializes in crafting
            Android, iOS, and cross-platform apps tailored to your business
            needs. Elevate your brand with our user- centric mobile solutions.
          </p>
        </div>
        <div className="md:w-1/2" 
         data-aos="fade-left"
         data-aos-duration="1000"
        >
          <img
            src="/assets/images/mobile-dev.jpg"
            className="object-cover h-full w-full mb-10 md:mb-0"
          />
        </div>
      </div>

      {/* Floating Divs */}
      <div className="container mx-auto mb-10">
        <div className="mt-12 text-center">
          <h1 className="text-xl md:text-2xl font-bold md:w-2/3 mx-auto">
            A Customer-Centric Approach To Bringing Innovations To Your Business
          </h1>
        </div>
        <div className="mt-12 md:flex flex-wrap items-center justify-center space-y-8 md:space-y-0 md:space-x-2"
        
        >
          {/* First style  */}
          <div className="w-full md:w-[24%]">
            <div className="justify-center flex">
              <div className="h-24 w-24 bg-gray-200 rounded-full flex items-center justify-center">
                <img
                  src="/assets/images/font-1.svg"
                  className="h-16 w-16 rounded-full items-center"
                  alt="icon-1"
                />
              </div>
            </div>
            <div className="mt-8 pb-12 bg-gray-200 rounded-md shadow-md p-8 mx-6 md:mx-0">
              <h3 className="font-bold text-lg md:text-xl w-4/6">
                You Reach Out to ThinkQuotient with Your Vision
              </h3>
              <p className="mt-4 text-sm md:text-base leading-relaxed w-full md:w-5/6">
                Transforming visions into mobile reality. We specialize in
                crafting Android, iOS, & cross-platform apps tailored to your
                business needs. Elevate your brand with our user-centric mobile
                solutions.
              </p>
            </div>
          </div>

          {/* Second style  */}
          <div className="w-full md:w-[24%]">
            <div className="justify-center flex">
              <div className="h-24 w-24 bg-gray-200 rounded-full flex items-center justify-center">
                <img
                  src="/assets/images/font-2.svg"
                  className="h-16 w-16 rounded-full items-center"
                  alt="icon-2"
                />
              </div>
            </div>
            <div className="mt-8 pb-16 bg-gray-200 rounded-md shadow-md p-8 md:ml-4 mx-6 md:mx-0 ">
              <h3 className="font-bold text-lg md:text-xl w-full md:w-8/12">
                We Analyze Your Requirements & Craft a Tailored Technology
                Strategy
              </h3>
              <p className="mt-4 text-sm md:text-base leading-relaxed w-full md:w-5/6">
                Leveraging our expertise and industry insights, we meticulously
                analyze your technology needs. Subsequently, we create a
                customized technology strategy that aligns precisely with your
                business objectives.
              </p>
            </div>
          </div>

          {/* Third style  */}
          <div className="w-full md:w-[24%]">
            <div className="justify-center flex">
              <div className="h-24 w-24 bg-gray-200 rounded-full flex items-center justify-center">
                <img
                  src="/assets/images/font-3.svg"
                  className="h-16 w-16 rounded-full items-center"
                  alt="icon-3"
                />
              </div>
            </div>
            <div className="mt-8 pb-16 bg-gray-200 rounded-md shadow-md p-8 md:ml-4 mx-6 md:mx-0 ">
              <h3 className="font-bold text-lg md:text-xl w-full md:w-10/12">
                Execution Begins on Deliverables
              </h3>
              <p className="mt-4 text-sm md:text-base leading-relaxed w-full md:w-4/5">
                With the plan in place, Think Quotient's team of skilled
                professionals springs into action. We initiate the execution
                phase, utilizing our expertise in technology consulting and
                innovation.
              </p>
            </div>
          </div>

          {/* Fourth style  */}
          <div className="w-full md:w-[24%]">
            <div className="justify-center flex">
              <div className="h-24 w-24 bg-gray-200 rounded-full flex items-center justify-center">
                <img
                  src="/assets/images/font-4.svg"
                  className="h-16 w-16 rounded-full items-center"
                  alt="icon-4"
                />
              </div>
            </div>
            <div className="mt-8 pb-24 bg-gray-200 rounded-md shadow-md p-8 ml-4 mr-6">
              <h3 className="font-bold text-lg md:text-xl w-full md:w-8/12">
                Precisely Delivered Technology Solutions
              </h3>
              <p className="mt-4 text-sm md:text-base w-full md:w-5/6 leading-relaxed">
                Your satisfaction is our ultimate goal. We deliver the
                technology solutions precisely as you envisioned, ensuring they
                seamlessly integrate with your business operations & objectives.
              </p>
            </div>
          </div>
        </div>
      </div>
      
  
      <ClientLogoCarousel />
      <Footer />
    </div>
    </>
  );
};

export default ProductDevelopment;
