import React, { useEffect } from "react";
import Header from "../../Components/Layout/Header";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import getHelmetContent from "../../Components/Common/HelmetData";

const Brochures = () => {
  useEffect(() => {
    document.title = "Brochures - Think Quotient";
    AOS.init();

  }, []);
  
  const pdfPaths = ["/assets/images/brochure.pdf"];

  const downloadPDF = (pdfPaths) => {
    pdfPaths.forEach((pdfPath) => {
      const link = document.createElement("a");
      link.href = pdfPath;
      link.download = pdfPath.split("/").pop();
      link.click();
    });
  };

  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();

  return (

    <>
      <Helmet>

        <meta name="title" content={metaTitle} />
        {/* Remaining meta tag */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* scipt for facebook */}
        {helmetContent.script}
        <script>
          {`
          (function (b, m, h, a, g) {
            b[a] = b[a] || [];
            b[a].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var k = m.getElementsByTagName(h)[0],
              e = m.createElement(h),
              c = a != "dataLayer" ? "&l=" + a : "";
            e.async = true;
            e.src = "https://www.googletagmanager.com/gtm.js?id=" + g + c;
            k.parentNode.insertBefore(e, k);
          })(window, document, "script", "dataLayer", "GTM-TN6B4BK");
          `
          }
        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>,

        <script>
          {
            `
      window.dataLayer = window.dataLayer || []; 
      function gtag() { 
        dataLayer.push(arguments) 
      } gtag("js", new Date()); gtag("config", "UA-143016865-1");
       `}
        </script>,

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>,

        <script>
          {`

        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments)}
        gtag('js', new Date());

        gtag('config', 'G-BSFPPN5E9D');
        `}

        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>,

        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments) }
      gtag('js', new Date());

      gtag('config', 'AW-574047969');
      `}

        </script>

      </Helmet>
      <div className="overflow-hidden">
        {/* Header Section */}
        <Header />
        {/* Banner Section */}
        <div>
          <div className="brochure-banner-sec flex items-center">
            <div className="container text-left text-white mx-auto">
              <div className="md:w-1/2 mx-4 md:mx-0"
                data-aos="fade-up"
                data-aos-duration="1000">
                <h1 className="text-2xl md:text-6xl font-semibold mb-4 ">
                  Brochures
                </h1>
                <Link to="/contact?scrollTo=contact-form">
                  <button className="btn-style-2 px-6 py-2">
                    Get In Touch With Us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* text and image box */}

        {/* First */}
        <div className="container mx-auto py-20 m-0 md:flex  service-boxes  md:space-x-11 px-4 md:px-0">
          <div className="md:w-1/2 flex flex-col items-center"
            data-aos="fade-right"
            data-aos-duration="1000">
            <img
              src="/assets/images/broch-1.png"
              className="object-cover  w-full"
              alt="Brochure 1"
            />

          </div>
          <div className="md:w-1/2   flex flex-col items-center"
            data-aos="fade-left"
            data-aos-duration="1000">
            <img
              src="/assets/images/broch-2.png"
              className="object-cover w-full"
              alt="Brochure 2"
            />

          </div>
        </div>
        <div className="w-full flex justify-center ">

          <button
            className="btn-style-1 px-6 py-2  md:w-1/4 mb-10"
            onClick={() => downloadPDF(pdfPaths)}
          >
            Download Brochure 
          </button>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Brochures;
