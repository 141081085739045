import React, { useState } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ImageModal from "./ImageModal";
import { useLocation } from "react-router-dom";

const Gallery = () => {


  const location = useLocation();
  const pageTitle = location.pathname === '/careers/work-with-us' ? 'Life At ThinkQuotient' : 'Gallery';
  const images = [
    "/assets/gallary/g1.jpeg",
  "/assets/gallary/g2.jpeg",
  "/assets/gallary/g3.jpeg",
  "/assets/gallary/g4.jpeg",
  "/assets/gallary/g5.jpeg",
  "/assets/gallary/g6.jpeg",
  "/assets/gallary/g7.jpeg",
  "/assets/gallary/g8.jpeg",
  "/assets/gallary/g9.jpeg",
  "/assets/gallary/g10.jpeg",
  "/assets/gallary/g11.jpeg",
  "/assets/gallary/g12.jpeg",
  "/assets/gallary/g13.jpeg",
  "/assets/gallary/g14.jpeg",
  "/assets/gallary/g15.jpeg",
  "/assets/gallary/g16.jpeg",

  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const openModal = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
    
  };

  const closeModal = () => {
    setSelectedImage("");
    setModalOpen(false);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  return (
    <div className="py-10 px-4 md:px-0">
      <div className="container mx-auto pb-4" id = "mod">
      {modalOpen && (
          <ImageModal
            isOpen={modalOpen}
            onClose={closeModal}
            imageUrl={selectedImage}
          />
        )}
        <h1 className="capitalize text-center sm:col-span-2 lg:col-span-4 text-3xl sm:text-5xl xl:text-3xl font-bold pb-10">
          {pageTitle}
        </h1>
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="w-full py-6">
              <img
                src={image.replace("1400/768", "600")}
                className="rounded object-cover pe-2 "
                alt={`gallery-image-${index}`}
                onClick={() => openModal(image)}
                style={{ cursor: "pointer", height: "350px", width: "400px"  }}
                
              />
            </div>
          ))}
        </Slider>
       
      </div>
    </div>
  );
};

export default Gallery;
