import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";

const BlogSec = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  // Blog data
  const blogData = [
    {
      id: 1,
      image: "/assets/images/blog-3.png",
      button: "Read More",
      blogHeading: "Navigating the Evolving Landscape: IT Freshers Hiring Trends in 2024",
      name: "- March 01, 2024 ",
      link: "https://thinkquotientblog2024.blogspot.com/2024/03/navigating-evolving-landscape-it.html",
      text: "In the dynamic world... ",
    },
    {
      id: 2,
      image: "/assets/images/blog-1.png",
      button: "Read More",
      blogHeading: "Why are IT Companies Hiring More Freshers ??",
      name: "- November 10, 2021",
      link: "https://thinkquotientblogs.blogspot.com/2021/11/why-are-it-companies-hiring-more_10.html",
      text: "After the pandemic world has come closer with help of internet and....",
    },
    {
      id: 3,
      image: "/assets/images/blog-2.png",
      button: "Read More",
      blogHeading: "COVID 19 IMPACT ON THE IT INDUSTRY",
      name: "- July 04, 2021 ",
      link: "https://thinkquotientblogs.blogspot.com/2021/07/covid-19-impact-on-it-industry.html",
      text: "How did the IT industry survive the virus? Why were there very few....",
    },
    
  ];

  const blogsRef = useRef(null);

  const blogsSettings = {
    dots: false,
    infinite: true,
    speed: 2000,
    autoplay: true,
    slidesToShow: 2, // Show three blogs by default
    slidesToScroll: 1,
    beforeChange: (_, next) => setActiveBlog(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // Show two blogs on screens with width 1024px or less
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1, // Show one blog on screens with width 767px or less (mobile view)
        },
      },
    ],
  };

  const goToBlogSlide = (index) => {
    blogsRef.current.slickGoTo(index);
  };

  const [activeBlog, setActiveBlog] = useState(0);

  return (
    <>
      {/* Blog Section */}
      <div div className="blogs-section py-20 " 
                  data-aos="fade-up"
      
      >
        <h1 className="text-3xl text-center font-bold mb-10">Blogs</h1>
        <Slider ref={blogsRef} {...blogsSettings}>
          {blogData.map((blog, index) => (
            <div
              key={blog.id}
              className={`blog-slide !w-11/12 ${
                activeBlog === index ? "active" : ""
              }`}
            >
              <div className="blog-image md:w-1/2 w-full">
                <img src={blog.image} alt={`Blog ${index + 1}`} className="h-full  object-cover "/>
              </div>
              <div className="blog-content md:w-1/2 space-y-4">
                <p className="testimonial-name">{blog.name}</p>

                <h1 className="md:text-lg font-bold">{blog.blogHeading}</h1>
                <p className="blog-text">{blog.text}</p>
                <a href={blog.link} target="_blank">
                  <button className="blog-btn-style px-4 py-1 2xl:w-1/2 w-3/4">
                    {blog.button}
                  </button>
                </a>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default BlogSec;
