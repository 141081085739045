import React, { useEffect } from "react";
import Header from "../Components/Layout/Header";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Layout/Footer";
import CountUp from "react-countup";
import ClientLogoCarousel from "../Components/Home/ClientLogoCarousel";
import OurTeam from "../Components/Home/OurTeam";
import Gallery from "../Components/Home/Gallery";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import getHelmetContent from "../Components/Common/HelmetData";
import usePageSEO from "../Components/Common/usePageSEO";

import { Helmet } from "react-helmet";

const About = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const sliderSettings = {
    dots: true,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    prevArrow: <div className="slick-arrow slick-prev ">&#x2190;</div>,
    nextArrow: <div className="slick-arrow slick-next">&#x2192;</div>,
  };

  const clientLogos = [
    "/assets/images/logo-1.png",
    "/assets/images/logo-2.png",
    "/assets/images/logo-3.webp",
    "/assets/images/logo-4.svg",
    "/assets/images/logo-2.png",
    "/assets/images/logo-3.webp",
    // Add more logos as needed
  ];

  const visionMissionContent = [
    {
      title: "Vision",
      description:
        "To be a beacon of innovation and excellence in the IT Service industry, driving technological advancement and transforming businesses through cutting-edge solutions. We envision a future where ThinkQuotient Software is synonymous with trust, expertise, and unparalleled IT services.",
      image: "/assets/images/vision-about.jpg",
    },
    {
      title: "Mission",
      description:
        "Integrity, Excellence, and Customer Value ThinkQuotient Software Pvt. Ltd. is built on a foundation of integrity, excellence, and a relentless pursuit of customer value. We uphold the highest standards of ethics in everything we do. Our commitment to excellence drives us to continuously improve our products and services, ensuring that our clients receive unmatched value. We are not just a software company; we are a trusted partner dedicated to the success of our clients.",
      image: "/assets/images/mission-about.jpg",
    },
    // Add more objects as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    arrow: true,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
    prevArrow: (
      <button type="button" className="slick-arrow slick-prev">
        Left &lt;
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-arrow slick-next">
        Right &gt;
      </button>
    ),
  };
  const pageTitle = "About - ThinkQuotient";
  const metaTitle = "Best IT Training Course with Drive Assistance";

  const metaDescription =
    "Learn about the best IT training course with drive assistance.";
  const helmetContent = getHelmetContent();
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content="metaDescription" />

        <meta property="og:type" content="website" />
        {/* <meta
          property="og:image"
          content="https://media.istockphoto.com/id/513247652/photo/panoramic-beautiful-view-of-mount-ama-dablam.jpg?s=2048x2048&w=is&k=20&c=UPPXnDKS803pmXDdx6zjQ7nMqe9MZBHbc1KeXeDC0HE="
        /> */}
      </Helmet>

      <div className="overflow-hidden">
        {/* Header Section */}
        <Header />

        {/* Banner Section */}
        <div className="flex overflow-hidden">
          <div className="about-us-banner-sec flex items-center   ">
            <div className="container mx-auto mt-20">
              <div
                className="text-white text-3xl md:text-6xl font-bold mx-4 md:mx-0"
                data-aos="fade-right"
              >
                What It Means To Be <br />
                <span className="about-us-banner-txt"> Think Quotient</span>
              </div>
            </div>
          </div>
        </div>

        {/* Middle Text */}
        <div
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        >
          <div className="middle-about-txt z-50  md:p-20 p-4">
            <p className="md:text-xl font-medium">
              An Organization's name should give a glimpse of what the business
              is about. The name serves as a doorway to the business.With that
              mind , the company "ThinkQuotient Software Pvt Ltd" was named as
              "ThinkQuotient" which represents a combination of cognitive
              intellectual skills, logical reasoning, and analytical skills.
            </p>
            <Link to="/careers/work-with-us">
              <button className="mt-8 btn-style-1  px-4 py-2 ">
                <p className="text-[14px]">Lets Work Together &#x2192;</p>{" "}
              </button>
            </Link>
          </div>
        </div>

        {/* Image with Slide */}
        <div className="container mx-auto m-8 about-vm-section">
          <p className="text-3xl font-bold mx-4 md:mx-0">Vision & Mission</p>

          {/* First Slide */}
          <Slider
            {...sliderSettings}
            className="relative pt-12 mx-4 md:mx-0"
            data-aos="fade-up"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            {visionMissionContent.map((item, index) => (
              <div key={index} className="">
                <div className="md:flex">
                  <img
                    src={item.image}
                    alt={`Vision/Mission ${index + 1}`}
                    className="vision-mission-box md:mb-20 md:w-6/12 "
                  />
                  <div className="vision-mission-box vm-description-box   flex  flex-col justify-center">
                    <p className="text-xl font-bold mb-8">{item.title}</p>
                    <p className="">{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>

         {/* <OurTeam /> */}

        <Gallery />

        {/* Timeline Section */}
        <div className="py-20 bg-gray-100">
          <div>
            <h1 className="text-3xl text-center font-bold mb-10">
              Clientele where our Resources work
            </h1>
          </div>
          <main
            id="main"
            className="section-margin section-padding overflow-x-scroll md:overflow-y-hidden md:mb-30"
            // data-aos="fade-up"
            // data-aos-offset="300"
            // data-aos-easing="ease-in-sine"
          >
            <div style={{ minHeight: "250px" }}>
              <div style={{ position: "relative" }}>
                <div className="timeline">
                  <div className="card-item-pcblue">
                    <span
                      aria-hidden="true"
                      className="fa fa-stack fa-2x circle-custom"
                      style={{ fontSize: "1.5rem" }}
                    >
                      2024
                    </span>
                    <div className="subcard">
                      <img src="/assets/images/2024.png" style={{ height: 432 }} />
                    </div>
                  </div>
                  <div className="card-item-yellow">
                    <span
                      aria-hidden="true"
                      className="fa fa-stack fa-2x circle-custom"
                      style={{ fontSize: "1.5rem" }}
                    >
                      2023
                    </span>
                    <div className="subcard">
                      <img src="/assets/images/2023.jpg" style={{ height: 432 }} />
                    </div>
                  </div>
                  <div className="card-item-yellow">
                    <span
                      aria-hidden="true"
                      className="fa fa-stack fa-2x circle-custom"
                      style={{ fontSize: "1.5rem" }}
                    >
                      2022
                    </span>
                    <div className="subcard">
                      <img src="/assets/images/2022.jpg" />
                    </div>
                  </div>
                  <div className="card-item-pcblue">
                    <span
                      aria-hidden="true"
                      className="fa fa-stack fa-2x circle-custom"
                      style={{ fontSize: "1.5rem" }}
                    >
                      2021
                    </span>
                    <div className="subcard">
                      <img src="/assets/images/2021.jpg" />
                    </div>
                  </div>
                  <div className="card-item-green">
                    <span
                      aria-hidden="true"
                      className="fa fa-stack fa-2x circle-custom"
                      style={{ fontSize: "1.5rem" }}
                    >
                      2020
                    </span>
                    <div className="subcard">
                      <img src="/assets/images/2020.jpg" />
                    </div>
                  </div>
                  <div className="card-item-orange">
                    <span
                      aria-hidden="true"
                      className="fa fa-stack fa-2x circle-custom"
                      style={{ fontSize: "1.5rem" }}
                    >
                      2019
                    </span>
                    <div className="subcard">
                      <img src="/assets/images/2019.jpg" />
                    </div>
                  </div>
                  <div className="card-item-yellow">
                    <span
                      aria-hidden="true"
                      className="fa fa-stack fa-2x circle-custom"
                      style={{ fontSize: "1.5rem" }}
                    >
                      2018
                    </span>
                    <div className="subcard">
                      <img src="/assets/images/2018.jpg" />
                    </div>
                  </div>
                  <div className="card-item-pcblue">
                    <span
                      aria-hidden="true"
                      className="fa fa-stack fa-2x circle-custom"
                      style={{ fontSize: "1.5rem" }}
                    >
                      2017
                    </span>
                    <div className="subcard">
                      <img src="/assets/images/2017.jpg" />
                    </div>
                  </div>
                </div>
                <div>
                  <br />
                </div>
              </div>
            </div>
          </main>
        </div>

        {/* second Slide */}
        {/* <div className="client-logo-carousel py-20 2xl:w-10/12 mx-auto relative">
                <h1 className="text-3xl text-center font-bold mb-10">Prestigious Clients</h1>
                <Slider {...settings}>
                    {clientLogos.map((logo, index) => (
                        <div key={index} className="client-logo">
                            <img src={logo} alt={`Client Logo ${index + 1}`} />
                        </div>
                    ))}
                </Slider>
            </div> */}
        {/* <ClientLogoCarousel /> */}

        {/* Last Content */}
        <div className="mt-8 md:flex about-tq-sec">
          <div className="md:flex md:m-20 md:w-3/4">
            <div className="md:w-2/6  mr-0">
              <img
                src="/assets/images/about-bottom.jpg"
                className="h-full w-full object-cover"
              />
            </div>
            <div className="primary-bg-color  md:w-4/6 md:p-20 p-10 2xl:pr-32 flex flex-col justify-center">
              <p className="md:text-2xl text-xl text-white font-bold">
                About ThinkQuotient
              </p>
              <p className="text-sm md:text-md mt-8 text-white ">
                ThinkQuotient Software Private Limited stands as a versatile
                company with a diverse portfolio of products and services. Our
                core service involves supplying highly skilled resources to
                esteemed companies in India. We take pride in serving a wide
                range of clients including Students, Corporations, Institutes,
                and Universities. Since our establishment in 2015, ThinkQuotient
                has embraced an unwavering path of progress. This exponential
                growth is a testament to the unwavering determination, visionary
                spirit, and collaborative teamwork that defines ThinkQuotient."
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-evenly items-start md:w-1/4 2xl:m-20 m-6 space-y-8 md:space-y-0">
            <div className="counter-item text-left">
              <h3 className="text-3xl font-bold mb-2">
                <CountUp end={7} duration={4} />
                <span className="text-secondary-color">+</span> Years
              </h3>
              <p>of Delivering Innovative Solutions</p>
            </div>

            <div className="counter-item text-left">
              <h3 className="text-3xl font-bold mb-2">
                <CountUp end={100} duration={4} />
                <span className="text-secondary-color">+</span> Years
              </h3>
              <p>of Collective IT Experience</p>
            </div>

            <div className="counter-item text-left">
              <h3 className="text-3xl font-bold mb-2">
                <CountUp end={6000} duration={4} />
                <span className="text-secondary-color">+</span>
              </h3>
              <p>Man-Hours Expertise</p>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </>
  );
};

export default About;
